import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: _ctx.localSnackbar.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localSnackbar.visible) = $event)),
    top: "",
    color: _ctx.snackbarColor,
    right: ""
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.localSnackbar.message), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "color"]))
}