
    import { defineComponent, ref, watch, watchEffect, Ref, onBeforeUnmount, onMounted } from 'vue';
    import EditorClass from '@/ckeditor/ckeditor.js';
    import { Document, Category } from '@/types/Document';
    import { Employee } from '@/types/Members';


  export default defineComponent({
    props: {
        selectedDocument: {
            type: Object as () => Document | null,
            default: null,
        },
        selectedDocumentPreview: {
            type: String,
            default: null,
        },
        selectedDocumentEmployeeDocument:{
            type: Object as () => Employee | null,
            default: null,
        },
        categories: {
            type: Array as () => Array<Category>,
            required: true,
        },
    },
    components: {
    },
    setup(props, { emit }) {
        const selectedCategory = ref<number>(1);
        const title = ref('');
        const content = ref('');
        const notes = ref('');
        const sort_order = ref(0);
        const editorDialog = ref(false);
        const showModal = ref(false);
        const employeeToEditId = ref(-1);
        const employeeToEditName = ref("");


        let editorInstance: EditorClass | null = null;



        const toolbarRef = ref<HTMLElement | null>(null);


        onBeforeUnmount(() => {
            toggleBodyScroll(false);
        });



        watch(
            () => props.selectedDocument,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.selectedDocumentPreview,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.selectedDocumentEmployeeDocument,
            (newVal) => {
                editorDialog.value = newVal != null;
            }
        );

        watch(
            () => props.categories,
            (newCategories) => {
                if (newCategories.length > 0) {
                    selectedCategory.value = newCategories[0].id;
                }
            },
            { immediate: true }
        );

        const editorContainer = ref(null);
        

        const save = () => {
            if (props.selectedDocument) {
                emit("update:selectedDocument", {
                    ...props.selectedDocument,
                    category_id: selectedCategory.value,
                    title: title.value,
                    content: content.value,
                    notes: notes.value,
                    sort_order: sort_order.value
                });
                closeEditor();
            }else if(props.selectedDocumentEmployeeDocument){
                emit("update:selectedDocumentEmployeeDocument", {
                    notes: content.value,
                    id: employeeToEditId.value,
                })
                closeEditor();
            }
        };


        watchEffect(() => {
            if (editorDialog.value && editorContainer.value && !editorInstance) {
                EditorClass.create(editorContainer.value, EditorClass.defaultConfig)
                    .then((editor) => {
                        editorInstance = editor;
                        updateEditorContent(); // Füge den Inhalt ein, sobald der Editor erstellt wurde
                        watch(
                            () => props.selectedDocument,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        watch(
                            () => props.selectedDocumentPreview,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        watch(
                            () => props.selectedDocumentEmployeeDocument,
                            () => {
                                updateEditorContent();
                            },
                            { immediate: true }
                        );

                        editorInstance.model.document.on('change:data', () => {
                            if (editorInstance) {
                                content.value = editorInstance.getData();
                            }
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else if (!editorDialog.value && editorInstance) {
                editorInstance.destroy().then(() => {
                    editorInstance = null;
                });
            }
        });


        watchEffect(() => {
            if (props.selectedDocument && props.selectedDocument.id != -1) {
                title.value = props.selectedDocument.title;
                content.value = props.selectedDocument.content;
                notes.value = props.selectedDocument.notes;
                sort_order.value = props.selectedDocument.sort_order
                // Finde die Kategorie in props.categories, die der category_id entspricht
                const category = props.categories.find(
                    (category) => category.id == props.selectedDocument.category_id
                );
                if (category) {
                    selectedCategory.value = category.id;
                }
                showModal.value= true;
            } else if (props.selectedDocumentPreview) {
                content.value = props.selectedDocumentPreview;
                showModal.value= true;
            } else if(props.selectedDocumentEmployeeDocument){
                content.value = props.selectedDocumentEmployeeDocument.notes;
                employeeToEditId.value = props.selectedDocumentEmployeeDocument.id;
                employeeToEditName.value = props.selectedDocumentEmployeeDocument.name;
                showModal.value= true;
            }else if(props.selectedDocument && props.selectedDocument.id == -1) {
                title.value = "";
                content.value = "";
                notes.value = "";
                sort_order.value = 0;
                // Aktualisiere selectedCategory nur, wenn props.categories definiert ist und mindestens ein Element enthält
                if (props.categories && props.categories.length > 0) {
                    selectedCategory.value = props.categories[0].id;
                }
                showModal.value= true;
            }
        });


        onBeforeUnmount(() => {
            if (editorInstance) {
                editorInstance.destroy();
                showModal.value= false;
            }
        });

        // ... setup function ...
        const updateEditorContent = () => {
            if (editorInstance && props.selectedDocument) {
                editorInstance.setData(props.selectedDocument.content);
            }else if( editorInstance && props.selectedDocumentPreview){
                editorInstance.setData(props.selectedDocumentPreview)
            }else if(editorInstance && props.selectedDocumentEmployeeDocument){
                editorInstance.setData(props.selectedDocumentEmployeeDocument.notes)
            }
        };

        watch(
            () => props.selectedDocument,
            () => {
                updateEditorContent();
            }
        );
        
        const closeEditor = () => {
            editorDialog.value = false;
            emit("close-document");
        };  

        const toggleBodyScroll = (disable) => {
            if (disable) {
                document.documentElement.classList.add("disable-scroll");
            } else {
                document.documentElement.classList.remove("disable-scroll");
            }
        };


        watchEffect(() => {
            toggleBodyScroll(editorDialog.value);
        });
        return {
            editorDialog,
            closeEditor,
            title,
            content,
            notes,
            editorContainer,
            save,
            selectedCategory,
            EditorClass,
            toolbarRef,
            sort_order,
            showModal,
            employeeToEditName,
        };
    },
  });
  