
    import { defineComponent, ref, computed, watch, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import api from "@/api";
    import { isTokenExpired } from "@/store";
    import { GlobalSettings } from "@/types/Settings";

    export default defineComponent({
        name: 'App',
        setup() {
            const drawer = ref(true);
            const store = useStore();
            const router = useRouter();
            const globalSettings = reactive<GlobalSettings>({});

            const fetchGlobalSettings = async () => {
                try {
                    const response = await api.get("settings/?action=getGlobalSettings");
                    if (response.data && response.data.settings) {
                        return response.data.settings;
                    }
                } catch (error) {
                    console.error("Error fetching global settings:", error);
                }
                return {};
            };

            const fetchSettings = async () => {
                const settings = await fetchGlobalSettings();
                for (const key in settings) {
                    globalSettings[key] = settings[key];
                }
            };
            
            
            onMounted(async () => {
                await fetchSettings();
            });


            watch(
                () => {
                    const state = store.state;
                    return !!state.jwt && !isTokenExpired(state);
                },
                (isLoggedIn) => {
                    if (!isLoggedIn) {
                        router.push({ name: 'login' });
                    }
                }
            );

            const isLoggedIn = computed(() => {
                const state = store.state;
                return !!state.jwt && !isTokenExpired(state);
            });

            const userProfile = computed(() => store.state.user);

            const goToProfile = () => {
                router.push('/profile');
            };

            const editProfile = () => {
                router.push('/edit-profile');
            };

            const logout = async () => {
                try {
                    const response = await api.post("account/?action=logout");
                    store.dispatch('logout');
                    router.push({ name: 'login' });
                } catch (error) {
                    console.error('Error logging out:', error);
                }
            };

            const hasPermission = (permissionName) => {
                const profile = userProfile.value;
                if (profile && profile.permissions) {
                    return profile.permissions.includes(permissionName) || profile.permissions.includes('ALL_PERMISSIONS');
                }
                return false;
            };

            const hasAdminPermission = () => {
                const profile = userProfile.value;
                if (profile && profile.permissions) {
                    return profile.permissions.some((perm) => perm.startsWith('ADMIN_')) || profile.permissions.includes('ALL_PERMISSIONS');
                }
                return false;
            };

            return { drawer, isLoggedIn, userProfile, goToProfile, editProfile, logout, hasPermission, hasAdminPermission, globalSettings };
        }
    });
