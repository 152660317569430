
  import { defineComponent, ref, onMounted, computed, watch } from "vue";
  import { LMap, LTileLayer, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
  import L, { LatLngExpression, bounds, latLngBounds, marker } from "leaflet";
  import "leaflet/dist/leaflet.css";
  import api from "@/api";
  import { Categories, Markers } from "@/types/Map";
  
  export default defineComponent({
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const showDialog = ref(false);
        const markerName = ref("");
        const ceo = ref("");
        const location = ref("");
        const phonenumber = ref("");
        const selectedCategory = ref(null);
        const clickedLatLng = ref(null);

        const selectedCategories = ref([]);

        const mapInstance = computed(() => map.value);
        const map = ref<any>(null);
    
        const zoom = ref(4);
        const center = ref([0, 0]);
        const url = ref(`/mapStyles/styleAtlas/{z}/{x}/{y}.jpg`);
        const attribution = ref("Kyuubi D. Dragon");

        const activeStyle = ref("styleAtlas");

        const categoryLayers = ref({});
    
        const changeMapStyle = (style) => {
            if(activeStyle.value == 'styleSatelite') mapInstance.value.leafletObject.removeLayer(SateliteStyle);
            else if(activeStyle.value == 'styleAtlas') mapInstance.value.leafletObject.removeLayer(AtlasStyle);
            else if(activeStyle.value == 'styleGrid') mapInstance.value.leafletObject.removeLayer(GridStyle);

            activeStyle.value = style;

            if (style === 'styleSatelite') {
                mapInstance.value.leafletObject.addLayer(SateliteStyle);
            } else if (style === 'styleAtlas') {
                mapInstance.value.leafletObject.addLayer(AtlasStyle);
            } else if (style === 'styleGrid') {
                mapInstance.value.leafletObject.addLayer(GridStyle);
            }
        };

        const center_x = 117.3;
        const center_y = 172.8;
        const scale_x = 0.02072;
        const scale_y = 0.0205;

        const transformation = new L.Transformation(
            scale_x, 
            center_x, 
            -scale_y, 
            center_y
        );

        const CUSTOM_CRS = L.extend({}, L.CRS.Simple, {
            projection: L.Projection.LonLat,
            transformation: transformation,
            scale: function (zoom) {
                return Math.pow(2, zoom);
            },
            zoom: function (sc) {
                return Math.log(sc) / 0.6931471805599453;
            },
            distance: function (pos1, pos2) {
                var x_difference = pos2.lng - pos1.lng;
                var y_difference = pos2.lat - pos1.lat;
                return Math.sqrt(
                    x_difference * x_difference + y_difference * y_difference
                );
            },
            infinite: true,
        });


        function customTileLayer(urlTemplate, options) {
            return new L.TileLayer(urlTemplate, {
                ...options,
                getTileUrl: function (coords) {
                const zoom = this._getZoomForUrl();
                if (zoom > options.maxNativeZoom) {
                    coords.z = options.maxNativeZoom;
                } else {
                    coords.z = zoom;
                }
                return L.Util.template(urlTemplate, L.extend(coords, this.options));
                },
            });
        }

        const AtlasStyle = customTileLayer('mapStyles/styleAtlas/{z}/{x}/{y}.jpg', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleAtlas map',
        });

        const SateliteStyle = customTileLayer('mapStyles/styleSatelite/{z}/{x}/{y}.jpg', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleSatelite map',
        });

        const GridStyle = customTileLayer('mapStyles/styleGrid/{z}/{x}/{y}.png', {
            minZoom: 0,
            maxZoom: 8,
            maxNativeZoom: 5,
            noWrap: false,
            attribution: '',
            id: 'styleGrid map',
        });

        const mapOptions = {
            crs: CUSTOM_CRS,
            minZoom: 2,
            maxZoom: 8,
            preferCanvas: true,
            noWrap: true,
            zoom: 2,
            maxNativeZoom: 5,
            doubleClickZoom: false,
        };


        const onMapClick = (event) => {
            const latLng = mapInstance.value.leafletObject.containerPointToLatLng(L.point(event.layerX, event.layerY));
            clickedLatLng.value = latLng;
            showDialog.value = true;
        };



        const addMarker = async () => {
            try {
                const response = await api.post("map/?action=addMarker", {
                    name: markerName.value,
                    ceo: ceo.value,
                    phonenumber: phonenumber.value,
                    location: location.value,
                    category_id: selectedCategory.value,
                    x_coordinate: clickedLatLng.value.lng,
                    y_coordinate: clickedLatLng.value.lat,
                });

                const selectedCategoryName = categories.value.find(category => category.id === selectedCategory.value)?.name;

                if (selectedCategoryName != "Hydrant") {
                    markerName.value = "";
                    ceo.value = "";
                    phonenumber.value = "";
                    location.value = "";
                }

                fetchMarker();
                showDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        };



        const customIcon = (icon) => {
            return L.icon({
                iconUrl: `/img/mapIcons/${icon}.png`,
                iconSize: [20, 20],
                iconAnchor: [10, 10],
                popupAnchor: [0, -10],
            });
        };

        const categories = ref<Categories[]>([]);

        const fetchCategories = async () => {
            try {
                const response = await api.get('map/?action=getCategories');
                categories.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        };

        const markers = ref<Markers[]>([]);

        const fetchMarker = async () => {
            try {
                const response = await api.get('map/?action=getMarker');
                markers.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        };

        const deleteMarker = async (marker) => {
            try {
                const response = await api.post('map/?action=deleteMarker', {id: marker.id});
                markers.value = markers.value.filter(element => element.id !== marker.id);
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        onMounted(async () => {
            await fetchCategories();
            await fetchMarker();
            selectedCategories.value = categories.value.map((category) => category.id);

            mapInstance.value.leafletObject.addLayer(AtlasStyle);
        });


        const editDialog = ref(false);
        const markerToEdit = ref(null);
        const editMarker = (marker) => {
            markerToEdit.value = marker;
            markerName.value = marker.name;
            ceo.value = marker.ceo;
            phonenumber.value = marker.phonenumber;
            location.value = marker.location;
            selectedCategory.value = marker.category_id;
            editDialog.value = true;
        };

        const updateMarker = async () => {
            try {
                const response = await api.post("map/?action=updateMarker", {
                    id: markerToEdit.value.id,
                    name: markerName.value,
                    ceo: ceo.value,
                    phonenumber: phonenumber.value,
                    location: location.value,
                    category_id: selectedCategory.value,
                    x_coordinate: markerToEdit.value.x_coordinate,
                    y_coordinate: markerToEdit.value.y_coordinate,
                });

                fetchMarker();
                editDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



        const search = ref("");
        const filteredMarkers = computed(() => {
            if (search.value === "") {
                return markers.value.filter((marker) =>
                    selectedCategories.value.includes(marker.category_id)
                );
            }
            return markers.value.filter((marker) =>
                selectedCategories.value.includes(marker.category_id) &&
                (marker.name.toLowerCase().includes(search.value.toLowerCase()) || marker.location.toLowerCase().includes(search.value.toLowerCase()))
            );
        });


        const focusOnMarker = (marker) => {
            console.log(marker);
            const newZoomLevel = 5; // Setzen Sie hier den gewünschten Zoom-Level
            mapInstance.value.leafletObject.flyTo(
                [marker.y_coordinate, marker.x_coordinate],
                newZoomLevel
            );
        };


        const filteredMarkersByCategory = (categoryId) => {
            return filteredMarkers.value.filter(marker => marker.category_id === categoryId);
        };


        return {
            map,
            changeMapStyle,
            zoom,
            center,
            url,
            attribution,
            mapOptions,
            showDialog,
            markerName,
            selectedCategory,
            clickedLatLng,
            categories,
            onMapClick,
            addMarker,
            ceo,
            phonenumber,
            markers,
            customIcon,
            deleteMarker,
            editMarker,
            editDialog,
            updateMarker,
            location,
            search,
            filteredMarkers,
            focusOnMarker,
            selectedCategories,
            filteredMarkersByCategory,
            errorSnackbar,
        };
    },
  });
  