
import { defineComponent, ref, onMounted, watch } from 'vue';
import { Company, Department, Rank, License } from '@/types/Members';
import api from '@/api';

type Employee = {
    name: string;
    personalid: string;
    phonenumber: string;
    birthdate: string;
    mail: string;
    servicenumber: string;
    entrydate: string;
    bankaccount: string;
    leavedate: string | null;
    sidejob: string;
    company: string[];
    department: string[];
    is_terminated: number;
    rank: string;
    rankId: number;
    license: string[];
    companies?: Record<string, Company>;
    departments?: Record<string, Department>;
    licenses?: Record<string, License>;
};


export default defineComponent({
    props: {
        editMode: {
        type: Boolean,
        default: false,
        },
        hideEditButton: {
            type: Boolean,
            default: false,
        },
        initialEmployee: {
            type: Object,
            default: () => ({
                name: '',
                personalid: '',
                phonenumber: '',
                birthdate: '',
                mail: '',
                servicenumber: '',
                bankaccount: '',
                entrydate: '',
                leavedate: '',
                sidejob: '',
                company: [],
                companies: [],
                department: [],
                departments:[],
                rank: '',
                rankId: 9,
                license: [],
                licenses: [],
                is_terminated: false,
            }),
        },
        companies: {
            type: Array,
            default: () => [],
        },
        departments: {
            type: Array,
            default: () => [],
        },
        ranks: {
            type: Array as () => Array<{ id: number; department: string; description: string; jobrole_id: number, jobrole_name: string, name:string, rankImage: string, sort_order:number}>,
            default: () => [],
        },
        licenses: {
            type: Array,
            default: () => [],
        },
    },

    setup(props, { emit }) {
        const dialog = ref(false);
        const employee = ref({ ...props.initialEmployee });
        const newEmployer = ref(false);
        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        onMounted(async () => {
            if (props.editMode) {
                employee.value = { ...props.initialEmployee };
            }
            employee.value.is_terminated = !!employee.value.leavedate;

            initializeLeaveDate(); // Fügen Sie diese Zeile hinzu
        });


        const initializeLeaveDate = () => {
            if (employee.value.is_terminated && employee.value.leavedate) {
                const leaveDate = new Date(employee.value.leavedate);
                employee.value.leavedate = `${leaveDate.getFullYear()}-${(leaveDate.getMonth() + 1).toString().padStart(2, '0')}-${leaveDate.getDate().toString().padStart(2, '0')}`;
            } else if (employee.value.is_terminated) {
                const currentDate = new Date();
                employee.value.leavedate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
            } else {
                employee.value.leavedate = '';
            }
        };





        const validateEmployee = () => {
            console.log(employee.value)
            if (!employee.value.name || !employee.value.servicenumber || !employee.value.entrydate || !employee.value.rankId) {
                return false;
            }
            return true;
        };



        const submitEmployee = async () => {
            if (!validateEmployee()) {
                console.error('Required fields are not filled');
                return;
            }
            const employeeData = {
                ...employee.value,
                company: employee.value.company,
                department: employee.value.department,
                license: employee.value.license,
            }
            try {
                if (!newEmployer.value) {
                    const response = await api.post('employee/?action=editEmployee', employeeData);
                } else {
                    const response = await api.post('employee/?action=createEmployee', employeeData);
                }
                emit('updateEmployeeList');
                dialog.value = false;
            } catch (error) {
                console.error('Error adding/updating employee:', error);
            }
        };


        const updateLeaveDate = () => {
            if (employee.value.is_terminated) {
                if (!employee.value.leavedate) {
                    const currentDate = new Date();
                    employee.value.leavedate = currentDate.toISOString().slice(0, 10);
                }
            } else {
                employee.value.leavedate = '';
            }
        };



        const openDialog = (employeeToEdit: Partial<Employee>, isNew: boolean) => {
            employee.value = { ...employeeToEdit };

            newEmployer.value = isNew;
            employee.value.is_terminated = employeeToEdit.is_terminated == 1;

            const rank = props.ranks.find((rank) => rank.id == employee.value.rankId);
            if (rank) {
                employee.value.rankId = rank.id;
            } else {
                const rankRecruit = props.ranks.find((rankRecruit) => rankRecruit.name == 'Recruit' || rankRecruit.name == 'Aspirant' || rankRecruit.name == 'Trainee');
                if (rankRecruit) {
                    employee.value.rankId = rankRecruit.id; // Beispiel: Standard-Rank-ID 9
                } else {
                    employee.value.rankId = 0; // oder einen anderen Standardwert
                    // Optional: Zeigen Sie eine Fehlermeldung an, wenn rankRecruit nicht gefunden wurde.
                    console.error("Standard-Rang 'Recruit', 'Aspirant' oder 'Trainee' wurde nicht gefunden.");
                }
            }

            if(employee.value.is_terminated) employee.value.leavedate = employeeToEdit.leavedate
            employee.value.company = employeeToEdit.companies
                ? (props.companies as Company[]).filter((company) =>
                    company.id in employeeToEdit.companies
                ).map((company) => company.id)
                : [];

            employee.value.department = employeeToEdit.departments
                ? (props.departments as Department[]).filter((department) =>
                    department.id in employeeToEdit.departments
                ).map((department) => department.id)
                : [];

            employee.value.license = employeeToEdit.licenses
                ? (props.licenses as License[]).filter((license) =>
                    license.id in employeeToEdit.licenses
                ).map((license) => license.id)
                : [];

            dialog.value = true;
            

            initializeLeaveDate();
        };

        watch(() => employee.value.is_terminated, (newValue, oldValue) => {
            updateLeaveDate();
        });







    return {
        dialog,
        employee,
        submitEmployee,
        openDialog,
        newEmployer,
        requiredRule,
        updateLeaveDate,
    };
  }
});
