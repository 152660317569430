import { createStore } from "vuex";
import jwtDecode from 'jwt-decode';

export interface State {
    user: { id: number; username: string; roles: string[]; permissions: string[]; mail_header: string; mail_footer: string; mail_header_neutral: string; mail_footer_neutral: string; signature: string; documentView: string; } | null;
    jwt: string | null;
    tokenExpiry: number | null;
}
  

export const isAuthenticated = (state: State) => {
  return !!state.user && !!state.jwt;
};

export function isTokenExpired(state: State): boolean {
  const tokenExpiry = state.tokenExpiry;
  if (!tokenExpiry) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime >= tokenExpiry;
}

export default createStore({
  state: {
    user: null,
    jwt: null,
    tokenExpiry: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setJwt(state, jwt) {
        state.jwt = jwt;
        if (jwt) {
            const decodedToken: any = jwtDecode(jwt);
            state.tokenExpiry = decodedToken.exp;
        } else {
            state.tokenExpiry = null;
        }
    },
    clearUser(state) {
        state.user = null;
    },
    clearJwt(state) {
        state.jwt = null;
        state.tokenExpiry = null;
    },
    setHeaderImage(state, headerImage) {
        state.user.mail_header = headerImage;
    },
    setFooterImage(state, footerImage) {
        state.user.mail_footer = footerImage;
    },
    setNeutralHeaderImage(state, headerImage) {
        state.user.mail_header_neutral = headerImage;
    },
    setNeutralFooterImage(state, footerImage) {
        state.user.mail_footer_neutral = footerImage;
    },
    setSignature(state, signature) {
        state.user.signature = signature;
    },
    setPermissions(state, permissions) {
        state.user.permissions = permissions;
    },
    setDocumentView(state, view){
        state.user.documentView = view;
    }
  },
  actions: {
    authenticate({ commit }, { user, jwt }) {
        console.log("Authenticating user:", user);
        console.log("Authenticating JWT:", jwt);
        commit("setUser", user);
        commit("setJwt", jwt);
        commit("setPermissions", user.permissions);

        // Speichern Sie die Benutzerdaten und den JWT-Token im Local Storage
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('jwt', jwt);
    },
    restoreAuthentication({ commit }) {
        const storedUser = localStorage.getItem('user');
        const storedJwt = localStorage.getItem('jwt');
        if (storedUser && storedJwt) {
            const user = JSON.parse(storedUser);
            commit("setUser", user);
            commit("setJwt", storedJwt);
        }
    },
    logout({ commit }) {
        commit("clearUser");
        commit("clearJwt");

        localStorage.removeItem('user');
        localStorage.removeItem('jwt');
    },
    updateJwt({ commit }, token) {
        commit("setJwt", token);
    },
  },
});
