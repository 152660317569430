
  import { defineComponent, ref, computed } from "vue";
  import { License, Employee } from "@/types/Members";
  import EmployeeForm from "./EmployeeForm.vue";
  import DocumentEditor from '@/components/document/DocumentEditor.vue';
  import api from "@/api";
  
  export default defineComponent({
    components: {
        EmployeeForm,
        DocumentEditor,
    },
    props: ["member", "logoURL", "companies", "departments", "ranks", "licenses", "showPreview"],
    setup(props, {emit}) {
        const expandedIndex = ref(-1);
        const editMode = ref(false);
        const errorSnackbar = ref<any>({ visible: false, message: "", color: "red"});

  
        const licensesArray = computed(() => {
            if (props.member.licenses && typeof props.member.licenses === "object") {
                return Object.values(props.member.licenses);
            }
            return [];
        });
      
        const getImage = (path:string) => {
            try{
                if(path != null){
                    return require('../../assets/ranks/' + path)
                }else{
                    return require('../../assets/logo.png')
                }
            }catch {
                return require('@/assets/logo.png')
            }
        }
  
        const filteredLicenses = (filter:string) => {
            return (licensesArray.value as License[]).filter(
                (license: License) => license.type === filter
            );
        };


  
        const expandCard = (index: number) => {
            if (expandedIndex.value === index) {
                expandedIndex.value = -1;
            } else {
            expandedIndex.value = index;
            }
        };
  
        const editUser = (user: number) => {
            // Find the employee in the list
            editMode.value = true;
            const employee = props.member;
            const { companies, departments, ranks, licenses } = props;
            const editEmployee = true;
            emit('edit', { employee, companies, departments, ranks, licenses, editEmployee });
        };


        const employeeForm = ref();


        const promotionTableHeaders = [
            { title: "Beförderungsdatum", key: "promotion_date" },
            { title: "Von Rang", key: "old_rank_name" },
            { title: "Zu Rang", key: "new_rank_name" },
        ];


        const promotionDialog = ref(false);
        const promotionsArray = computed(() => {
            if (props.member.promotions && typeof props.member.promotions === "object") {
                return Object.values(props.member.promotions);
            }
            return [];
        });

        const showPromotionsDialog = () => {
            promotionDialog.value = true;
        };

        function formatDate(date: any) {
            if (!date) return "";
            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        }

        function processFilteredLicenses(type: string): string[] {
            const filtered = filteredLicenses(type);
            const results: string[] = [];

            filtered.forEach((license) => {
                results.push(license.license);
            });

            return results;
        }


        //ONLY FIREDEPARTMENT
        const personalFile = computed(() => { return `<table border="0" cellpadding="0" cellspacing="0" style="width:100%">
	<tbody>
		<tr>
			<td colspan="3">
			<table align="left" border="1" cellpadding="1" cellspacing="1" style="width:48%">
				<tbody>
					<tr>
						<td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center; width:200px"><span style="color:#FFFFFF"><strong>Mitarbeiterakte</strong></span></td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Dienstnummer, Vor- und Nachname:</strong></span></td>
						<td>
						<ul>
							<li>${props.member.servicenumber} - ${props.member.name}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Geburtsdatum:</strong></td>
						<td>
						<ul>
							<li>${formatDate(props.member.birthdate)}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Venture Account:&nbsp;</strong></span></td>
						<td>
						<ul>
							<li>${props.member.mail}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><span style=""><strong>Positionen:</strong></span></td>
						<td>
						<ul>
							<li>${props.member.rank}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Telefonnummer:</strong></td>
						<td>
						<ul>
							<li>${props.member.phonenumber}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Ausweis ID:</strong></td>
						<td>
						<ul>
							<li>${props.member.personalid}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Kontonummer:</strong></td>
						<td>
						<ul>
							<li>${props.member.bankaccount}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Führerscheine:</strong></td>
						<td>
						<ul>
						<li>${processFilteredLicenses('driverlicense').join(' | ')} | ${processFilteredLicenses('diverlicense').join(' | ')}</li> 
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Nebentätigkeit:</strong></td>
						<td>
						<ul>
							<li>${props.member.sidejob}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Spindnummern:</strong></td>
						<td>
						<ul>
							<li>ES:${props.member.servicenumber}|${props.member.servicenumber}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Vertrag gesendet / unterschrieben:</strong></td>
						<td>
						<ul>
						<li>${formatDate(props.member.entrydate)} / ${formatDate(props.member.entrydate)}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Probezeit Ende:</strong></td>
						<td>
						<ul>
							<li>&nbsp;</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Eintrittsdatum:</strong></td>
						<td>
						<ul>
						<li>${formatDate(props.member.entrydate)}</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Beförderungen:</strong></td>
						<td>
						<ul>
							<li>&nbsp;</li>
						</ul>
						</td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170); width:250px"><strong>Austrittsdatum:</strong></td>
						<td>
						<ul>
							<li>&nbsp;</li>
						</ul>
						</td>
					</tr>
				</tbody>
			</table>

			<table align="right" border="1" cellpadding="1" cellspacing="1" style="width:48%">
				<tbody>
					<tr>
						<td colspan="3" style="background-color:rgb(255, 0, 51); text-align:center; white-space:nowrap"><span style="color:#FFFFFF"><em><u><strong>Ausgehändigtes Eigentum vom LSFD</strong></u></em></span></td>
					</tr>
					<tr>
						<td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Ausrüstung</strong></td>
						<td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Ausgehändigt Ja/Nein</strong></td>
						<td style="text-align: center; white-space: nowrap; background-color: rgb(170, 170, 170);"><strong>Zurückgegeben Ja/Nein</strong></td>
					</tr>
					<tr>
						<td style="text-align:center; white-space:nowrap"><strong>Funkgerät und Funkgurt</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
					</tr>
					<tr>
						<td style="text-align:center; white-space:nowrap"><strong>FD Dienstmarke</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
					</tr>
					<tr>
						<td style="text-align:center; white-space:nowrap"><strong>FD Arbeitskleidung</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
					</tr>
					<tr>
						<td style="text-align:center; white-space:nowrap"><strong>Taschenlampe</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
						<td style="text-align:center; white-space:nowrap"><strong>[&nbsp; ]</strong></td>
					</tr>
				</tbody>
			</table>
            <table align="right" border="1" cellpadding="1" cellspacing="1" style="width:48%">
				<tbody>
					<tr>
						<td colspan="5" style="background-color:rgb(255, 0, 51); text-align:center"><span style="color:#FFFFFF"><strong>Vorfälle / Abmahnungen / Suspendierungen</strong></span></td>
					</tr>
					<tr>
						<td style="background-color: rgb(170, 170, 170);"><strong>Datum</strong></td>
						<td style="background-color: rgb(170, 170, 170);"><strong>Vorfall</strong></td>
						<td style="background-color: rgb(170, 170, 170);"><strong>Zeugen</strong></td>
						<td style="background-color: rgb(170, 170, 170);"><strong>Konsequenz</strong></td>
						<td style="background-color: rgb(170, 170, 170);"><strong>Eintragung</strong></td>
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
					</tr>
				</tbody>
			</table>
			</td>
		</tr>
	</tbody>
</table>

                                `;
        });


                            
        const personFile = computed(() => { return `<table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Mitarbeiterdaten</span></strong></td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Name</strong></td>
                                        <td><strong>${props.member.name}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Telefonnummer</strong></td>
                                        <td><strong>${props.member.phonenumber}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Dienstnummer</strong></td>
                                        <td><strong>${props.member.servicenumber}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Rang</strong></td>
                                        <td><strong>${props.member.rank}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Abteilung &amp; Position</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Company</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Lizenzen</span></strong></td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>PKW</strong></td>
                                        <td><strong>${(licensesArray.value  as License[]).some(license => license.license === 'PKW') ? 'X' : ''}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>LKW</strong></td>
                                        <td><strong>${(licensesArray.value  as License[]).some(license => license.license === 'LKW') ? 'X' : ''}</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Boot</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'Boot') ? 'X' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Helikopter</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'Pilot') ? 'X' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>OWD</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'OWD') ? 'X' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>AOWD</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'AOWD') ? 'X' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>DSD</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'DSD') ? 'X' : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Rescue Diver</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'RSD') ? 'X' : ''}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Fahrzeugeinweisung</span></strong></td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Fleet 1</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fleet 2</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Fleet 3</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Ladder</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Rescue</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Engine</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Helikopter</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Boot</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Allg. Seilwinden</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><strong><span style="color:#D3D3D3">Ausbildungsnachweise</span></strong></td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Groundtrainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Advanced Trainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Ladder Trainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Rescue Trainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Air Operation Trainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Promotiontrainings</strong></td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table border="1" cellpadding="1" cellspacing="1" style="width:600px">
                                <tbody>
                                    <tr>
                                        <td colspan="2" style="background-color:rgb(255, 0, 51); text-align:center"><span style="color:#D3D3D3"><strong>Sonstiges</strong></span></td>
                                    </tr>
                                    <tr>
                                        <td style="width:200px"><strong>Erweiterter EH-Kurs</strong></td>
                                        <td>${(licensesArray.value  as License[]).some(license => license.license === 'erw. Erstehilfe') ? 'X' : ''}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <p><span style="color:#FF0000"><em>*Informationen in dieser Akte dürfen nur von der Leitung sowie dazu berechtigten Personen&nbsp;abgeändert/hinzugefügt/entfernt werden</em></span></p>
                            `;
        });

        async function copyToClipboard(file:string) {
            try {
                if(file == 'personalFile'){
                    await navigator.clipboard.writeText(personalFile.value);
                    if(props.showPreview == true) content.value = personalFile.value;
                    errorSnackbar.value.message = 'Personalakte erfolgreich kopiert.';
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = 'green';
                }else if(file == 'personFile'){
                    await navigator.clipboard.writeText(personFile.value);
                    if(props.showPreview == true) content.value = personFile.value;
                    errorSnackbar.value.message = 'Personenakte erfolgreich kopiert.';
                    errorSnackbar.value.visible  = true;
                    errorSnackbar.value.color  = 'green';
                }
            } catch (err) {
                errorSnackbar.value.message = 'Personalakte erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
            }
        }
        //ONLY FIREDEPARTMENT


        //Preview File System

        const content = ref(null);
        function resetSelectedDocument(){
            content.value = null;
        }

        //Preview File System

        //Employee File Notes
        const selectedDocumentEmployeeNotes = ref<Employee | null>(null);
        function resetSelectedDocumentEmployeeNotes(){
            selectedDocumentEmployeeNotes.value = null;
        }

        const updateSelectedDocumentEmployeeNotes = async (updatedEmployee: any) => {
            if (updatedEmployee.id != -1) {
                try {
                    const response = await api.post('employee/?action=updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes});
                    if(response.data.success){
                        emit('updateNotes', { id: updatedEmployee.id, notes: updatedEmployee.notes });
                    }
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        };

        async function editNotes(member: Employee) {
            try {
                selectedDocumentEmployeeNotes.value = member;
            } catch (err) {
                errorSnackbar.value.message = 'Personalakte erfolgreich kopiert.';
                errorSnackbar.value.visible  = true;
            }
        }
        //Employee File Notes

  
      return {
        expandedIndex,
        expandCard,
        employeeForm,
        editUser,
        filteredLicenses,
        getImage,
        editMode,
        promotionDialog,
        showPromotionsDialog,
        promotionsArray,
        promotionTableHeaders,
        formatDate,
        copyToClipboard,
        errorSnackbar,
        content,
        resetSelectedDocument,
        resetSelectedDocumentEmployeeNotes,
        selectedDocumentEmployeeNotes,
        updateSelectedDocumentEmployeeNotes,
        editNotes,
      };
    },
  });
  