
import { defineComponent, onMounted, ref } from 'vue';
import api from '@/api';
import { Users, Groups } from '@/types/User';

export default defineComponent({
setup() {
    const errorSnackbar = ref<any>({ visible: false, message: "" });
    const headers = [
        { title: 'Username', align: 'start', sortable: false, key: 'username' },
        { title: 'Email', align: 'start', sortable: false, key: 'email' },
        { title: 'Gruppen', align: 'start', sortable: false, key: 'groups' },
        { title: 'Mail Header', align: 'start', sortable: false, key: 'mail_header' },
        { title: 'Mail Footer', align: 'start', sortable: false, key: 'mail_footer', },
        { title: 'Mail Header Neutral', align: 'start', sortable: false, key: 'mail_header_neutral' },
        { title: 'Mail Footer Neutral', align: 'start', sortable: false, key: 'mail_footer_neutral' },
        { title: 'Signature', align: 'start', sortable: false, key: 'signature' },
        { title: 'Letzter Login', align: 'start', sortable: false, key: 'last_login' },
        { title: 'Actions', key: 'actions', sortable: false }
    ];

    const users = ref<Users[]>([]);
    const bannDialog = ref(false);
    const unbannDialog = ref(false);
    const selectedUser = ref<Users | null>(null);

    const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

    const openBannDialog = (user) => {
        selectedUser.value = user;
        bannDialog.value = true;
    };

    const openUnbannDialog = (user) => {
        selectedUser.value = user;
        unbannDialog.value = true;
    };

    const snackbar = ref({
        value: false,
        color: '',
        message: '',
    });




    const fetchUsers = async () => {
        try {
            const response = await api.get('admin/user/?action=getUserOverview');
            users.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await api.get('admin/user/?action=getGroups');
            groups.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };


    const bannUser = async (user) => {
        console.log('Bann user:', user.id);
        let userId = user.id;
        try {
            const response = await api.post("admin/user/?action=bannUser", { id: userId });
            
            await fetchUsers();

            bannDialog.value = false;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };

    const unbannUser = async (user) => {
        console.log('Bann user:', user.id);
        let userId = user.id;
        try {
            const response = await api.post("admin/user/?action=unbannUser", { id: userId });

            await fetchUsers();

            unbannDialog.value = false;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };

    const copyLinkToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(
            () => {
                errorSnackbar.value.message = "Link erfolgreich kopiert";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = "green";
            },
            (err) => {
                errorSnackbar.value.message = "Ein Fehler ist aufgetreten";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color  = "red";
            }
        );
    };


    const newUserDialog = ref(false);
    const newUser = ref({
        username: '',
        email: '',
        groups: [],
        password: '',
    });

    function isFormValid() {
        return (
            newUser.value.username !== '' &&
            newUser.value.email !== '' &&
            newUser.value.groups.length !== 0 &&
            newUser.value.password !== ''
        );
    }

    const groups = ref<Groups[]>([]);

    const addNewUser = async () => {
        try {
            const response = await api.post("admin/user/?action=addNewUser", newUser.value);
            await fetchUsers();

            if(response.data.error){
                snackbar.value.value = true;
                snackbar.value.color = 'error';
                snackbar.value.message = response.data.error;
            }else{
                newUserDialog.value = false;
                newUser.value = {username: '', email: '', groups: [], password: ''};
            }
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };



    const editUserDialog = ref(false);
    const editedUser = ref({
        id: '',
        username: '',
        email: '',
        groups: '',
        allGroups: [],
        mail_header: '',
        mail_footer: '',
        mail_header_neutral: '',
        mail_footer_neutral: '',
        signature: '',
    });

    const openEditUserDialog = (user) => {
        editedUser.value = { ...user };
        editUserDialog.value = true;
    };

    const updateUser = async () => {
        try {
            const response = await api.post("admin/user/?action=updateUser", editedUser.value);
            await fetchUsers();

            editUserDialog.value = false;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };

    const passwordRules = {
        required: (value) => !!value || "Erforderlich",
        minLength: (value) => value.length >= 8 || "Mindestlänge von 8 Zeichen erforderlich",
        matchPassword: (value) =>
            value === passwordReset.value.password || "Passwörter stimmen nicht überein",
    };

    
    const resetPasswordDialog = ref(false);
    const passwordReset = ref({
        password: '',
        passwordRetry: ''
    });
    const openResetPasswordDialog = (user) => {
        editedUser.value = { ...user };
        resetPasswordDialog.value = true;
    }

    const resetPassword = async () => {
        try {
            const response = await api.post("admin/user/?action=resetPassword", { id: editedUser.value.id, password: passwordReset.value.password });
            await fetchUsers();

            resetPasswordDialog.value = false;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };



    onMounted(async () => {
        await fetchUsers();
        await fetchGroups();
    });



    return {
        headers,
        users,
        bannUser,
        openBannDialog, // Add the openBannDialog method to the return object
        bannDialog,
        unbannUser,
        openUnbannDialog,
        unbannDialog,
        selectedUser,
        newUserDialog,
        newUser,
        groups,
        addNewUser,
        requiredRule,
        isFormValid,
        snackbar,
        editUserDialog,
        editedUser,
        openEditUserDialog,
        updateUser,
        errorSnackbar,
        copyLinkToClipboard,
        passwordReset,
        passwordRules,
        resetPassword,
        resetPasswordDialog,
        openResetPasswordDialog
    };
},
});
