
  import { defineComponent, ref, onMounted, Ref, watchEffect, computed } from "vue";
  import api from "@/api";
  import { Category, Template, TemplateField, Field, ExtractedField, FieldValue } from "@/types/Templates";
  import { useStore } from 'vuex';
  import draggable from 'vuedraggable'
  
  export default defineComponent({
    components: {
        draggable,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const categories = ref<Category[]>([]);
        const dialog = ref(false);
        const selectedTemplate = ref<Template | null>(null);
        const store = useStore();
        const fieldValues: Ref<{ [key: string]: FieldValue }> = ref({});
        const templateFields = ref<ExtractedField[]>([]);
        const selectedTemplateFields = ref<ExtractedField[]>([]);
        const searchInput = ref('');
        const showSortingModal = ref(false);
        const editedValue = ref('');
        const editingField = ref({ type: '', id: -1 });
        

        watchEffect(() => {
            const newFieldValues: Record<string, string | string[]> = {};

            templateFields.value.forEach((field) => {
                if(field.field_value != "") newFieldValues[field.field_name] = field.field_value;
            });
            console.log(newFieldValues)

            fieldValues.value = newFieldValues;
        });

        const editTemplateDialog = ref(false);
        const editedTemplate: Ref<any> = ref({
            id: null,
            category_id: null,
            name: "",
            description: "",
            icon: "",
            text: "",
        });

        const openEditDialog = (template: Template) => {
            editedTemplate.value = { ...template };
            selectedIcon.value.name = editedTemplate.value.icon;
            editTemplateDialog.value = true;
        };

        const editTemplate = async () => {
            // Validierung (optional, je nach Bedarf)
            if (
                editedTemplate.value.name.trim() === '' ||
                editedTemplate.value.description.trim() === '' ||
                editedTemplate.value.text.trim() === ''
            ) {
                console.error('Alle Felder müssen ausgefüllt sein');
                return;
            }

            try {
                // API-Anfrage zum Aktualisieren des Templates
                await api.post('templates/?action=updateTemplate', {
                    id: editedTemplate.value.id,
                    category_id: editedTemplate.value.category_id,
                    name: editedTemplate.value.name,
                    description: editedTemplate.value.description,
                    icon: selectedIcon.value.name,
                    text: editedTemplate.value.text,
                });

                // Aktualisieren der lokalen Liste der Templates
                const categoryIndex = categories.value.findIndex(
                    (cat) => cat.id === editedTemplate.value.category_id
                );
                if (categoryIndex !== -1) {
                    const templateIndex = categories.value[categoryIndex].templates.findIndex(
                        (tmpl) => tmpl.id === editedTemplate.value.id
                    );
                    if (templateIndex !== -1) {
                        categories.value[categoryIndex].templates.splice(templateIndex, 1, editedTemplate.value);
                    }
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }

            // Schließen des Dialogs
            editTemplateDialog.value = false;
            fetchCategories();
        };



        const addTemplateDialog = ref(false);
        const addCategorieDialog = ref(false);
        const newTemplate = ref({
            category_id: 1,
            name: "",
            text: "{{header}}\nSehr geehrter Herr {{employee}}\n....\n\n{{footer}}",
            description: "",
            icon: "",
        });

        const newCategorie = ref({
            name: "",
        });

        const filteredCategories = computed<Category[]>(() => {
            if (!searchInput.value.trim()) {
                return categories.value;
            }

            return categories.value
                .map((category) => ({
                ...category,
                templates: category.templates.filter((template) => {
                    const searchTerm = searchInput.value.toLowerCase();
                    const nameMatch = template.name.toLowerCase().includes(searchTerm);
                    const descriptionMatch = template.description
                    ? template.description.toLowerCase().includes(searchTerm)
                    : false;

                    return nameMatch || descriptionMatch;
                }),
            }))
            .filter((category) => category.templates.length > 0);
        });


        const onCategoryMoved = () => {
            for (let i = 0; i < categories.value.length; i++) {
                categories.value[i].sort_order = i + 1;
            }
        };

        const saveCategorySorting = async () => {
            try {
                console.log("Sending category data:", categories.value);
                const response = await api.post("templates/?action=saveCategorySorting", JSON.stringify(categories.value));

                if (response.data.success) {
                console.log("Category sorting saved successfully");
                } else {
                console.error("Failed to save category sorting:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }

            showSortingModal.value = false;
        };

        const saveTemplateSorting = async (items) => {
            try {
                const sortedItems = items.map((item, index) => ({ id: item.id, sort_order: index }));
                const response = await api.post("templates/?action=saveTemplateSorting", JSON.stringify(sortedItems));
                if (response.data.success) {
                    errorSnackbar.value.message = 'Sortierung gespeichert.';
                    errorSnackbar.value.visible  = true;	
                    errorSnackbar.value.color = "green";
                } else {
                    console.error("Failed to save template sorting:", response.data.error);
                }
            } catch (error) {
                if (error.response) {
                    errorSnackbar.value.message = error.response.data.error;
                } else {
                    errorSnackbar.value.message = "An unknown error occurred";
                }
                errorSnackbar.value.visible  = true;	
            }
        };




        function getFieldValue(uniqueFieldName: string, field_value: any): string {
            // Split the uniqueFieldName into the original fieldName and index
            const [fieldName, index] = uniqueFieldName.split(':');

            if (fieldName === 'header') {
                return `[img]${store.state.user.mail_header}[/img]`;
            } else if (fieldName === 'footer') {
                return `[img]${store.state.user.mail_footer}[/img]`;
            } else if (fieldName === 'header_neutral') {
                return `[img]${store.state.user.mail_header_neutral}[/img]`;
            } else if (fieldName === 'footer_neutral') {
                return `[img]${store.state.user.mail_footer_neutral}[/img]`;
            } else if (fieldName === 'signature') {
                return `[img]${store.state.user.signature}[/img]`;
            }else if (field_value.length > 0){
                return field_value;
            }
            return '';
        }



        function extractTemplateFields(templateText: string): ExtractedField[] {
            const variablePattern = /\{\{ *(\w+)((?: *, *\w+)*) *\}\}/g;
            const fields: ExtractedField[] = [];
            const fieldCounts: { [fieldName: string]: number } = {};

            let match;
            while ((match = variablePattern.exec(templateText)) !== null) {
                const fieldName = match[1];
                const properties = match[2].split(',').map(s => s.trim()).filter(s => s !== '');

                // Count the occurrences of the fieldName
                fieldCounts[fieldName] = (fieldCounts[fieldName] || 0) + 1;

                // Always generate a unique field name based on the count
                const uniqueFieldName = `${fieldName}:${fieldCounts[fieldName]}`;

                // Create a new field object
                const field = {
                    field_name: uniqueFieldName,
                    is_multiple: false,
                    is_required: false,
                    field_label: "",
                    field_type: "text", // Set default field_type to "text" (it will be updated from allFields later)
                    field_description: "",
                    field_value: "",
                };
                field.field_value = getFieldValue(uniqueFieldName, '');
                fields.push(field);

                // Update field properties based on the matched properties
                for (const property of properties) {
                    if (property === "multiple") {
                        field.is_multiple = true;
                    } else if (property === "required") {
                        field.is_required = true;
                    }
                }
            }

            // Update field_type from allFields
            for (const field of fields) {
                const originalFieldName = field.field_name.split(':')[0];
                const matchingField = allFields.value.find(f => f.field_name === originalFieldName);
                if (matchingField && matchingField.field_type) {
                    field.field_type = matchingField.field_type;
                }
                if(matchingField && matchingField.field_value){
                    field.field_value = matchingField.field_value;
                }
            }

            return fields;
        }





        function openDialog(template: Template) {
            selectedTemplate.value = template;

            const extractedFields = extractTemplateFields(template.text); // Pass the template ID
            const dbFields = allFields.value.filter(
                (field: Field) => field.template_id === template.id || field.template_id === null
            );
            templateFields.value = mergeExtractedAndDbFields(extractedFields, dbFields);
            
            fieldValues.value = {};
            for (const field of templateFields.value) {
                fieldValues.value[field.field_name] = field.field_value;
            }
            
            dialog.value = true;
        }




        function mergeExtractedAndDbFields(extractedFields: ExtractedField[], dbFields: Field[]): Field[] {
            const mergedFields: Field[] = [];
            for (const extractedField of extractedFields) {
                const dbField = dbFields.find((f) => f.field_name === extractedField.field_name);
                if (dbField) {
                mergedFields.push({
                    ...dbField,
                    is_multiple: extractedField.is_multiple,
                    is_required: extractedField.is_required,
                    field_type: extractedField.field_type,
                    field_value: getFieldValue(extractedField.field_name, extractedField.field_value), // Update field_value based on the unique field name
                });
                } else {
                mergedFields.push({
                    id: 0,
                    template_id: null,
                    field_name: extractedField.field_name,
                    field_label: extractedField.field_name, // Set field_label to field_name as a fallback
                    is_multiple: extractedField.is_multiple,
                    is_required: extractedField.is_required,
                    field_type: extractedField.field_type,
                    field_description: extractedField.field_description,
                    field_value: getFieldValue(extractedField.field_name, extractedField.field_value), // Update field_value based on the unique field name
                });
                }
            }
            return mergedFields;
        }




        async function addNewTemplate() {
            try {
                await api.post("templates/?action=addTemplate", newTemplate.value);
                fetchCategories(); // Refresh categories after adding the new template
                addTemplateDialog.value = false;
                resetFields();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function addNewCategorie() {
            try {
                await api.post("templates/?action=addCategorie", newCategorie.value);
                fetchCategories(); // Refresh categories after adding the new template
                addCategorieDialog.value = false;
                newCategorie.value.name = "";
                resetFields();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        function openAddTemplateDialog() {
            addTemplateDialog.value = true;
        }

        function openAddCategorieDialog() {
            addCategorieDialog.value = true;
        }

        async function fetchCategories() {
            try {
                const response = await api.get("templates/?action=getTemplates");
                categories.value = response.data;
                } catch (error){
                console.error("Error fetching categories:", error);
            }
        }

        const allFields = ref<Field[]>([]);
        async function fetchFields() {
            try {
                const response = await api.get("templates/?action=getFields");
                allFields.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        function submitForm() {
            // Process form data here, e.g., send it to the API
            dialog.value = false;
        }

        function getStringArray(value: FieldValue): string[] {
            if (Array.isArray(value)) {
                return value;
            }
            return [value];
        }



        function addFieldValue(fieldName: string) {
            if (!Array.isArray(fieldValues.value[fieldName])) {
                const fieldValueArray = getStringArray(fieldValues.value[fieldName]);
                fieldValueArray.push("");
                fieldValues.value[fieldName] = fieldValueArray;
            }
            (fieldValues.value[fieldName] as string[]).push("");
        }



        function renderTemplate() {
            if (!selectedTemplate.value) {
                return "";
            }

            let renderedTemplate = selectedTemplate.value.text;
            const fieldCounts = {};

            // Find all instances of fields in the template
            const variablePattern = /\{\{ *(.*?) *\}\}/g;
            const matches = renderedTemplate.match(variablePattern) || [];

            // Loop through each field and replace duplicates with numbered fields
            for (let i = 0; i < matches.length; i++) {
                const match = matches[i];
                const fieldName = match.substring(2, match.length - 2).trim();

                if (Object.prototype.hasOwnProperty.call(fieldCounts, fieldName)) {
                    // Increment field count
                    fieldCounts[fieldName]++;
                } else {
                    // Initialize field count
                    fieldCounts[fieldName] = 1;
                }

                // Replace the field with the numbered field
                const numberedFieldName = `${fieldName}:${fieldCounts[fieldName]}`;
                renderedTemplate = renderedTemplate.replace(new RegExp(`\\{\\{ *${fieldName} *\\}\\}`), `{{${numberedFieldName}}}`);

            }

            // Replace fields with their corresponding values
            for (const uniqueFieldName in fieldValues.value) {
                const fieldValue = fieldValues.value[uniqueFieldName];
                const renderedFieldValue = Array.isArray(fieldValue)
                    ? fieldValue.join(", ")
                    : fieldValue;

                // Replace all instances of the field, including numbered ones
                const variablePattern = new RegExp(`\\{\\{ *${uniqueFieldName} *\\}\\}`, "g");
                renderedTemplate = renderedTemplate.replace(variablePattern, renderedFieldValue);
            }

            return renderedTemplate;
        }

        function updateFieldValue(fieldName, value) {
            const originalField = templateFields.value.find(field => field.field_name === fieldName);
            if (originalField && originalField.field_type === 'date') {
                fieldValues.value[fieldName] = formatDate(value);
            } else {
                fieldValues.value[fieldName] = value;
            }
        }

        function updateDateFieldValue(fieldName, isoDateString) {
            fieldValues.value[fieldName] = formatDate(isoDateString);
        }


        function formatDate(isoDateString) {
            const date = new Date(isoDateString);
            console.log(date);
            if (isNaN(date.getTime())) {
                return isoDateString;
            }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }






        async function copyToClipboard() {
            try {
                const renderedText = renderTemplate(); // Stelle sicher, dass du die renderTemplate-Funktion entsprechend deiner Komponente anpasst
                await navigator.clipboard.writeText(renderedText);
                console.log('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }
        }


        //Icons für den Dropdown:
        const icons = [
            { name: 'mdi-home', path: 'mdi-Home' },
            { name: 'mdi-account', path: 'mdi-Account' },
            { name: 'mdi-email', path: 'mdi-Email' },
            { name: 'mdi-phone', path: 'mdi-Phone' },
            { name: 'mdi-map-marker', path: 'mdi-MapMarker' },
            { name: 'mdi-calendar', path: 'mdi-Calendar' },
            { name: 'mdi-lock', path: 'mdi-Lock' },
            { name: 'mdi-magnify', path: 'mdi-Magnify' },
            { name: 'mdi-plus', path: 'mdi-Plus' },
            { name: 'mdi-minus', path: 'mdi-Minus' },
            { name: 'mdi-check', path: 'mdi-Check' },
            { name: 'mdi-close', path: 'mdi-Close' },
            { name: 'mdi-cog', path: 'mdi-cog' },
            { name: 'mdi-information', path: 'mdi-Information' },
            { name: 'mdi-alert', path: 'mdi-Alert' },
            { name: 'mdi-help', path: 'mdi-Help' },
            { name: 'mdi-delete', path: 'mdi-Delete' },
            { name: 'mdi-pencil', path: 'mdi-Pencil' },
            { name: 'mdi-eye', path: 'mdi-Eye' },
            { name: 'mdi-eye-off', path: 'mdi-EyeOff' },
            { name: 'mdi-folder', path: 'mdi-Folder' },
            { name: 'mdi-file', path: 'mdi-File' },
            { name: 'mdi-camera', path: 'mdi-Camera' },
            { name: 'mdi-image', path: 'mdi-Image' },
            { name: 'mdi-play', path: 'mdi-Play' },
            { name: 'mdi-pause', path: 'mdi-Pause' },
            { name: 'mdi-skip-next', path: 'mdi-SkipNext' },
            { name: 'mdi-skip-previous', path: 'mdi-SkipPrevious' },
            { name: 'mdi-volume-high', path: 'mdi-VolumeHigh' },
            { name: 'mdi-volume-medium', path: 'mdi-VolumeMedium' },
            { name: 'mdi-volume-low', path: 'mdi-VolumeLow' },
            { name: 'mdi-volume-off', path: 'mdi-VolumeOff' },
            { name: 'mdi-download', path: 'mdi-Download' },
            { name: 'mdi-upload', path: 'mdi-Upload' },
            { name: 'mdi-account-cancel', path: 'mdi-account-cancel' },
            { name: 'mdi-account-check', path: 'mdi-account-check' },
            { name: 'mdi-account-minus', path: 'mdi-account-minus' },
            { name: 'mdi-account-plus', path: 'mdi-account-plus' },
            { name: 'mdi-account-cash', path: 'mdi-account-cash' },
            { name: 'mdi-alert-outline', path: 'mdi-alert-outline' },
            { name: 'mdi-shopping', path: 'mdi-shopping' },
            { name: 'mdi-receipt', path: 'mdi-receipt' },
            { name: 'mdi-file-sign', path: 'mdi-file-sign' },
            { name: 'mdi-clock-alert-outline', path: 'mdi-clock-alert-outline' },
            { name: 'mdi-account-tie', path: 'mdi-account-tie-woman' },
        ];


        const selectedIcon = ref({ name: '', path: '' });
        const iconPickerDialog = ref(false);

        const selectIcon = (iconItem:any) => {
            console.log(iconItem.path)
            selectedIcon.value = iconItem;
            newTemplate.value.icon = iconItem.path;
            iconPickerDialog.value = false;
        };

        const resetFields = () => {
            newTemplate.value = {
                category_id: 1,
                name: '',
                description: '',
                text: '',
                icon: '',
            };
            selectedIcon.value = {
                name: '',
                path: '',
            };
        };





        const startEditingField = (fieldType: string, fieldId: number) => {
            editingField.value = { type: fieldType, id: fieldId };
            if (fieldType === 'categoryName' && categories.value) {
                const category = categories.value.find(cat => cat.id === fieldId);
                if (category) {
                    editedValue.value = category.name;
                }
            }
            // Füge hier weitere Bedingungen für andere Feldtypen hinzu
        };

        const cancelEditingField = () => {
            editingField.value = { type: '', id: -1 };
        };

        async function updateField(fieldId: number) {
            if (editedValue.value.trim() === '') return;

            if (editingField.value.type === 'categoryName' && categories.value) {
                try {
                    const category = categories.value.find(cat => cat.id === fieldId);
                    if (!category) {
                        return;
                    }

                    await api.post('templates/?action=updateCategoryName', {
                        id: fieldId,
                        name: editedValue.value,
                    });

                    category.name = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }

            // Füge hier weitere Bedingungen für andere Feldtypen hinzu

            cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
        }



        onMounted(() => {
            fetchCategories();
            fetchFields();
            const state = store.state;
        });

        function calculateRows() {
            const minRows = 10;
            const maxRows = 30;
            const text = renderTemplate();
            const numberOfLineBreaks = (text.match(/\n/g) || []).length;

            return Math.min(minRows + numberOfLineBreaks, maxRows);
        }




        return {
            categories,
            calculateRows,
            dialog,
            selectedTemplate,
            fieldValues,
            openDialog,
            submitForm,
            addFieldValue,
            renderTemplate,
            addTemplateDialog,
            newTemplate,
            openAddTemplateDialog,
            addNewTemplate,
            templateFields,
            copyToClipboard,
            allFields,
            icons,
            selectedIcon,
            iconPickerDialog,
            selectIcon,
            resetFields,
            filteredCategories,
            searchInput,
            openAddCategorieDialog, 
            addCategorieDialog,
            newCategorie,
            addNewCategorie,
            showSortingModal,
            onCategoryMoved,
            saveCategorySorting,
            editingField,
            editedValue,
            startEditingField,
            updateField,
            cancelEditingField,
            editTemplateDialog,
            editedTemplate,
            openEditDialog,
            editTemplate,
            errorSnackbar,
            updateFieldValue,
            updateDateFieldValue,
            saveTemplateSorting,
        };
    },
});
