
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
      const form = ref(null);
      const formValid = ref(false);
      const globalSettings = ref({
        settings:{
            siteName: '',
            siteLogo: '',
            employeeNavigation: '',
        }
      });
  
      const rules = {
        required: (value: string) => !!value || 'Required',
      };
  
      const fetchSettings = async () => {
        try {
            const response = await api.get('settings/?action=getGlobalSettings');
            globalSettings.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
        }
      };
  
      const saveSettings = async () => {
        if (form.value.validate()) {
          try {
            await api.post('/admin/settings/?action=updateSettings', globalSettings.value);
            form.value.resetValidation();
          } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
          }
        }
      };
  
      onMounted(async () => {
        await fetchSettings();
      });
  
      return {
        form,
        formValid,
        globalSettings,
        rules,
        saveSettings,
        errorSnackbar,
      };
    },
  });
  