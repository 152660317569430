
  import { defineComponent, onMounted, ref, Ref, computed, watch } from 'vue';
  import DocumentEditor from '@/components/document/DocumentEditor.vue';
  import api from '@/api';
  import { Category, Document } from '@/types/Document';
  import draggable from 'vuedraggable'
  import { useRoute } from 'vue-router';
  import store from '@/store';
  
  export default defineComponent({
    components: {
      DocumentEditor,
      draggable,
    },
    setup() {
        const route = useRoute();
        const docSite = ref(route.meta.docType);
        const search = ref('');
        const categories = ref<Category[]>([]);
        const editedValue = ref('');
        const editingField = ref({ type: '', id: -1 });
        const selectedDocument = ref<Document | null>(null);
        const showSortingModal = ref(false);
        const addCategorieDialog = ref(false);
        const newCategorie = ref({
            name: "",
            site: docSite.value,
        });

        const viewMode = ref("tiles");
        const toggleViewMode = () => {
            viewMode.value = viewMode.value === "tiles" ? "table" : "tiles";
        };

        const headers = [
            { title: 'Titel', align: 'start', sortable: true, key: 'title' },
            { title: 'Kategorie', align: 'start', sortable: true, key: 'categoryName' },
            { title: 'Ersteller', align: 'start', sortable: false, key: 'creator_name' },
            { title: 'Erstellt am', align: 'start', sortable: false, key: 'created_at' },
            { title: 'Letzte Änderung', align: 'start', sortable: true, key: 'updated_at' },
            { title: 'Actions', key: 'actions', sortable: false }
        ];

        const errorSnackbar = ref<any>({ visible: false, message: "" });


        const flattenedDocuments = computed(() => {
            let documents = [];
            categories.value.forEach((category) => {
                category.documents.forEach((document) => {
                    documents.push({
                    ...document,
                    categoryName: category.name,
                    });
                });
            });
            console.log(documents)
            return documents;
        });



        const deleteConfirmationDialog = ref(false);
        const documentToDeleteId = ref<number | null>(null);

        const openDeleteConfirmationDialog = (id: number) => {
            documentToDeleteId.value = id;
            deleteConfirmationDialog.value = true;
        }

        // eslint-disable-next-line
        const openDocumentEditor = (document: Document | null = null, isNew: boolean = false) => {
            if (isNew) {
                selectedDocument.value = {
                    id: -1, // Setzen Sie eine temporäre ID, z. B. -1, um ein neues Dokument zu kennzeichnen
                    title: '',
                    content: '',
                    category_id: 1, // Setzen Sie die Kategorie-ID auf -1 oder auf die ID der gewünschten Kategorie
                    sort_order: -1,
                    notes: '',
                    creator: 0,
                    creator_name: '',
                    updated_at: '',
                    created_at: ''
                };
            } else {
                selectedDocument.value = document;
            }
            console.log(selectedDocument.value)
        };
        const updateSelectedDocument = async (updatedDocument: Document) => {
            if (updatedDocument.id === -1) {
                // Implementieren Sie die Logik zum Hinzufügen eines neuen Dokuments
                try {
                    const response = await api.post('document/?action=addDocument', updatedDocument);
                    const addedDocument = response.data;
                    // Fügen Sie das hinzugefügte Dokument zur entsprechenden Kategorie hinzu
                    fetchCategoriesAndDocuments();
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } else {
                try {
                    const response = await api.post('document/?action=updateDocument', updatedDocument);
                    const addedDocument = response.data;
                    // Fügen Sie das hinzugefügte Dokument zur entsprechenden Kategorie hinzu
                    fetchCategoriesAndDocuments();
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }
        };

        const filteredFlattenedDocuments = computed(() => {
            if (!search.value) {
                return flattenedDocuments.value;
            }
            const searchLowercase = search.value.toLowerCase();
            return flattenedDocuments.value.filter((doc) =>
                doc.title.toLowerCase().includes(searchLowercase)
            );
        });

        const filteredCategories = computed(() => {
            if (!search.value) {
                return categories.value;
            }

            const searchLowercase = search.value.toLowerCase();

            return categories.value.map((category) => {
                const filteredDocuments = category.documents.filter((doc) =>
                doc.title.toLowerCase().includes(searchLowercase)
                );

                return { ...category, documents: filteredDocuments };
            });
        });

        watch(() => route.path, () => {
            docSite.value = route.meta.docType;
            fetchCategoriesAndDocuments();
        });

        onMounted( () => {
            viewMode.value = store.state.user.documentView;
            fetchCategoriesAndDocuments();
        });



        async function fetchCategoriesAndDocuments() {
            try {
                const response = await api.post("document/?action=getCategoriesAndDocuments", {site: docSite.value});
                categories.value = response.data;
            } catch (error){
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        const resetSelectedDocument = () => {
            selectedDocument.value = null;
        };



        const startEditingField = (fieldType: string, fieldId: number) => {
            editingField.value = { type: fieldType, id: fieldId };
            if (fieldType === 'categoryName' && categories.value) {
                const category = categories.value.find(cat => cat.id === fieldId);
                if (category) {
                    editedValue.value = category.name;
                }
            }
            // Füge hier weitere Bedingungen für andere Feldtypen hinzu
        };

        const cancelEditingField = () => {
            editingField.value = { type: '', id: -1 };
        };

        async function updateField(fieldId: number) {
            if (editedValue.value.trim() === '') return;

            if (editingField.value.type === 'categoryName' && categories.value) {
                try {
                    const category = categories.value.find(cat => cat.id === fieldId);
                    if (!category) {
                        return;
                    }

                    await api.post('document/?action=updateCategoryName', {
                        id: fieldId,
                        name: editedValue.value,
                    });

                    category.name = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }

            // Füge hier weitere Bedingungen für andere Feldtypen hinzu

            cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
        }

        const saveCategorySorting = async () => {
            try {
                console.log("Sending category data:", categories.value);
                const response = await api.post("document/?action=saveCategorySorting", JSON.stringify(categories.value));

                if (response.data.success) {
                console.log("Category sorting saved successfully");
                } else {
                console.error("Failed to save category sorting:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }

            showSortingModal.value = false;
        };

        function openAddCategorieDialog() {
            addCategorieDialog.value = true;
        }

        async function addNewCategorie() {
            try {
                let response = await api.post("document/?action=addCategorie", newCategorie.value);

                if(response.data.success){
                    fetchCategoriesAndDocuments(); // Refresh categories after adding the new template
                    addCategorieDialog.value = false;
                    newCategorie.value.name = "";
                }else{
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        async function deleteDocument(id:number) {
            try {
                await api.post("document/?action=deleteDocument", { id });
                fetchCategoriesAndDocuments(); // Refresh categories after adding the new template
                documentToDeleteId.value = 0;
                deleteConfirmationDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        const saveDocumentSorting = async (items) => {
            try {
                const sortedItems = items.map((item, index) => ({ id: item.id, sort_order: index }));
                const response = await api.post("document/?action=saveDocumentSorting", JSON.stringify(sortedItems));
                if (response.data.success) {
                    errorSnackbar.value.message = 'Sortierung gespeichert.';
                    errorSnackbar.value.visible  = true;	
                    errorSnackbar.value.color = "green";
                } else {
                    console.error("Failed to save template sorting:", response.data.error);
                }
            } catch (error) {
                if (error.response) {
                    errorSnackbar.value.message = error.response.data.error;
                } else {
                    errorSnackbar.value.message = "An unknown error occurred";
                }
                errorSnackbar.value.visible  = true;	
            }
        };



        return{
            categories,
            editingField,
            editedValue,
            startEditingField,
            cancelEditingField,
            updateField,
            openDocumentEditor,
            selectedDocument,
            updateSelectedDocument,
            resetSelectedDocument,
            saveCategorySorting,
            showSortingModal,
            openAddCategorieDialog,
            addCategorieDialog,
            addNewCategorie,
            newCategorie,
            deleteDocument,
            openDeleteConfirmationDialog,
            deleteConfirmationDialog,
            documentToDeleteId,
            viewMode,
            toggleViewMode,
            headers,
            flattenedDocuments,
            search,
            filteredFlattenedDocuments,
            filteredCategories,
            errorSnackbar,
            saveDocumentSorting,
        }
    }
  });
  