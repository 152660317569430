
  import { defineComponent, ref, onMounted } from "vue";
  import api from "@/api";
  import { useStore } from "vuex";
  
  export default defineComponent({
    components: {},
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const store = useStore();
        const lastLogin = ref("2023-04-28 09:00");
        const groups = ref(["Group A", "Group B", "Group C"]);
        const headerImage = ref("");
        const footerImage = ref("");
        const headerImageNeutral = ref("");
        const footerImageNeutral = ref("");
        const email = ref("");
        const signature = ref("");
        const selectedDocumentView = ref("");

        const documentType = [
            { name: 'Kachel', key: 'tiles' },
            { name: 'Tabelle', key: 'table' }
        ];


        const passwordChange = ref({
            password: '',
            passwordRetry: '',
            oldPassword: ''
        });

        const passwordForm = ref(null);
        const isPasswordFormValid = ref(false);
        const passwordMessage = ref(null);

        const passwordRules = {
            required: (value) => !!value || "Erforderlich",
            minLength: (value) => value.length >= 8 || "Mindestlänge von 8 Zeichen erforderlich",
            matchPassword: (value) =>
                value === passwordChange.value.password || "Passwörter stimmen nicht überein",
        };

        
        const changePassword = async () => {
            try {
                const response = await api.post('/account/?action=changePassword', passwordChange.value)
                if (response.data.success) {
                    passwordMessage.value = {
                        type: "success",
                        text: "Passwort wurde erfolgreich geändert",
                    };
                    passwordForm.value.reset();
                } else {
                    passwordMessage.value = {
                        type: "error",
                        text: response.data.message || "Ein Fehler ist aufgetreten",
                    };
                }
            } catch (error) {
                passwordMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const templateMessage = ref(null);
        const templateMessageNeutral = ref(null);
        const templateMessageSignature = ref(null);
        const saveTemplateImages = async (neutral: boolean) => {
            try {
                // Send an API request to update the header and footer images on the server
                const response = await api.post("/account/?action=updateTemplateImages", {
                    headerImage: headerImage.value,
                    footerImage: footerImage.value,
                    headerImageNeutral: headerImageNeutral.value,
                    footerImageNeutral: footerImageNeutral.value,
                    neutral: neutral,
                });

                if (response.data.success) {
                    // Update the Vuex store
                    if(neutral){
                        store.commit("setNeutralHeaderImage", headerImageNeutral.value);
                        store.commit("setNeutralFooterImage", footerImageNeutral.value);
                        templateMessageNeutral.value = {
                            type: "success",
                            text: "Template Header und Footer wurden erfolgreich geändert.",
                        };
                    }else {
                        store.commit("setHeaderImage", headerImage.value);
                        store.commit("setFooterImage", footerImage.value);
                        templateMessage.value = {
                            type: "success",
                            text: "Template Header und Footer wurden erfolgreich geändert.",
                        };
                    }
                } else {
                    if(neutral){
                        templateMessageNeutral.value = {
                            type: "error",
                            text: response.data.message || "Ein Fehler ist aufgetreten",
                        };
                    }else{
                        templateMessage.value = {
                            type: "error",
                            text: response.data.message || "Ein Fehler ist aufgetreten",
                        };
                    }
                }
            } catch (error) {
                templateMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const saveSignature = async () => {
            try {
                // Send an API request to update the header and footer images on the server
                const response = await api.post("/account/?action=updateSignature", {
                    signature: signature.value,
                });

                if (response.data.success) {
                    // Update the Vuex store
                        store.commit("setSignature", signature.value);
                        templateMessageSignature.value = {
                            type: "success",
                            text: "Template Signature wurden erfolgreich geändert.",
                        }
                } else {
                    templateMessageSignature.value = {
                        type: "error",
                        text: response.data.message || "Ein Fehler ist aufgetreten",
                    }
                }
            } catch (error) {
                templateMessage.value = {
                    type: "error",
                    text: "Ein Fehler ist aufgetreten",
                };
            }
        };

        const saveSettings = async () => {
            try {
            const response = await api.post("/account/?action=updateSettings", {
                documentView: selectedDocumentView.value,
            });

            if (response.data.success) {
                // Update the Vuex store
                store.commit("setDocumentView", selectedDocumentView.value);
                errorSnackbar.value.message = "Einstellungen gespeichert.";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color = "green";
            } else {
                errorSnackbar.value.message = response.data.message || "Ein Fehler ist aufgetreten";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color = "red";
            }
            } catch (error) {
                errorSnackbar.value.message = "Ein Fehler ist aufgetreten";
                errorSnackbar.value.visible  = true;
                errorSnackbar.value.color = "red";
            }
        };

        onMounted(async () => {
            headerImage.value = store.state.user.mail_header;
            footerImage.value = store.state.user.mail_footer;
            headerImageNeutral.value = store.state.user.mail_header_neutral;
            footerImageNeutral.value = store.state.user.mail_footer_neutral;
            signature.value = store.state.user.signature;
            selectedDocumentView.value = store.state.user.documentView;
        });
        


      return {
        store,
        lastLogin,
        groups,
        headerImage,
        headerImageNeutral,
        footerImage,
        footerImageNeutral,
        email,
        changePassword,
        passwordChange,
        passwordForm,
        isPasswordFormValid,
        passwordRules,
        passwordMessage,
        saveTemplateImages,
        templateMessage,
        templateMessageNeutral,
        templateMessageSignature,
        errorSnackbar,
        saveSignature,
        signature,
        documentType,
        selectedDocumentView,
        saveSettings,
      };
    },
  });
  