import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "event-container" }
const _hoisted_2 = {
  key: 0,
  class: "event"
}
const _hoisted_3 = {
  key: 0,
  class: "event-title",
  style: {"float":"left"}
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "event" }
const _hoisted_6 = {
  class: "event-title",
  style: {"float":"left"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_vue_cal = _resolveComponent("vue-cal")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_title = _resolveComponent("v-card-title")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "auto",
              class: "mb-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_menu, {
                  "open-on-hover": "",
                  "close-on-content-click": false,
                  class: "ma-1"
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_btn, _normalizeProps(_guardReactiveProps(props)), {
                      default: _withCtx(() => [
                        _createTextVNode(" Filter ")
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, { class: "custom-checkbox-list" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: user.id
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_checkbox, {
                                    label: user.username,
                                    modelValue: _ctx.filterOptions.assignedTo,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterOptions.assignedTo) = $event)),
                                    value: user.id,
                                    onChange: _ctx.applyFilters
                                  }, null, 8, ["label", "modelValue", "value", "onChange"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_vue_cal, {
          ref: "calendar",
          events: _ctx.events,
          onEventDblclick: _ctx.handleEventClick,
          onCellClick: _ctx.handleCellClick,
          onEventDrop: _ctx.handleEventDragEnd,
          "active-view": "month",
          "events-on-month-view": "short",
          "show-all-day-events": "short",
          "disable-views": ['years', 'year', 'day', 'week'],
          locale: "de",
          "editable-events": { title: true, drag: true, resize: false, delete: true, create: false },
          draggable: true,
          resizable: false,
          "hide-view-selector": ""
        }, {
          event: _withCtx(({ event, view }) => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.getEventDayIndex(event) < 5)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_v_tooltip, {
                      activator: "parent",
                      location: "top"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(event.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    (view === 'month')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("span", {
                            style: _normalizeStyle({ color: event.color }),
                            class: "ml-1"
                          }, "●", 4),
                          _createTextVNode(" " + _toDisplayString(_ctx.formatDateCalPreview(event.start)) + " " + _toDisplayString(event.title), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.eventsPerDay[_ctx.formatDate(event.start, true).split('T')[0]] > 4 && _ctx.getEventDayIndex(event) === 5)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "show-more",
                    style: {"cursor":"pointer"},
                    onClick: _withModifiers(($event: any) => (_ctx.handleShowMore(_ctx.formatDate(event.start, true).split('T')[0])), ["stop"]),
                    "data-show-more": ""
                  }, " Mehr anzeigen ", 8, _hoisted_4))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["events", "onEventDblclick", "onCellClick", "onEventDrop"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.eventDialog,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.eventDialog) = $event)),
          "max-width": "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  flat: "",
                  dense: "",
                  color: "transparent"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, { class: "headline" }, {
                      default: _withCtx(() => [
                        _createTextVNode("Termin Details")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "red",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteDialog = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Löschen")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.selectedEvent.title,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEvent.title) = $event)),
                                  modelModifiers: { lazy: true },
                                  rules: [_ctx.requiredRule],
                                  label: "Titel",
                                  required: ""
                                }, null, 8, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.selectedEvent.content,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedEvent.content) = $event)),
                                  label: "Untertitel",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.selectedEvent.start,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedEvent.start) = $event)),
                                  label: "Startdatum",
                                  type: "datetime-local",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.selectedEvent.end,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedEvent.end) = $event)),
                                  label: "Enddatum",
                                  type: "datetime-local",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.selectedEvent.recurring,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedEvent.recurring) = $event)),
                                  items: _ctx.recurring,
                                  "item-title": "text",
                                  "item-value": "value",
                                  label: "Wiederholen",
                                  chips: ""
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, {
                                  modelValue: _ctx.selectedEvent.contentFull,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedEvent.contentFull) = $event)),
                                  label: "Beschreibung",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_color_picker, {
                                  modelValue: _ctx.selectedEvent.color,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedEvent.color) = $event)),
                                  label: "Farbe",
                                  swatches: _ctx.predefinedColors,
                                  "swatches-label": "Vorgefertigte Farben",
                                  "hide-inputs": "",
                                  "show-swatches": "",
                                  "hide-canvas": "",
                                  "hide-sliders": "",
                                  mode: "hexa",
                                  width: "100%"
                                }, null, 8, ["modelValue", "swatches"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.selectedEvent.assigned_to,
                                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedEvent.assigned_to) = $event)),
                                  items: _ctx.users,
                                  "item-title": "username",
                                  "item-value": "id",
                                  label: "Zugewiesen an",
                                  chips: "",
                                  multiple: ""
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "text",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.eventDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Abbrechen ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "text",
                      disabled: !_ctx.isFormValid(true),
                      onClick: _ctx.updateEvent
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Aktualisieren ")
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.newEventDialog,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.newEventDialog) = $event)),
          "max-width": "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Neuer Termin ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.newEvent.title,
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newEvent.title) = $event)),
                                  rules: [_ctx.requiredRule],
                                  label: "Titel",
                                  required: ""
                                }, null, 8, ["modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.newEvent.content,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newEvent.content) = $event)),
                                  label: "Beschreibung",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.newEvent.start,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newEvent.start) = $event)),
                                  label: "Startdatum",
                                  type: "datetime-local",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  modelValue: _ctx.newEvent.end,
                                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newEvent.end) = $event)),
                                  label: "Enddatum",
                                  type: "datetime-local",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.newEvent.recurring,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.newEvent.recurring) = $event)),
                                  items: _ctx.recurring,
                                  "item-title": "text",
                                  "item-value": "value",
                                  label: "Wiederkehrend",
                                  chips: "",
                                  required: ""
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_textarea, {
                                  modelValue: _ctx.newEvent.contentFull,
                                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newEvent.contentFull) = $event)),
                                  label: "Beschreibung",
                                  required: ""
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_color_picker, {
                                  modelValue: _ctx.newEvent.color,
                                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newEvent.color) = $event)),
                                  label: "Farbe",
                                  swatches: _ctx.predefinedColors,
                                  "swatches-label": "Vorgefertigte Farben",
                                  "hide-inputs": "",
                                  "show-swatches": "",
                                  "hide-canvas": "",
                                  "hide-sliders": "",
                                  mode: "hexa",
                                  width: "100%"
                                }, null, 8, ["modelValue", "swatches"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.newEvent.assigned_to,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.newEvent.assigned_to) = $event)),
                                  items: _ctx.users,
                                  "item-title": "username",
                                  "item-value": "id",
                                  label: "Zugewiesen an",
                                  chips: "",
                                  multiple: "",
                                  required: ""
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "text",
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.newEventDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Abbrechen ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "text",
                      disabled: !_ctx.isFormValid(false),
                      onClick: _ctx.createEvent
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Erstellen ")
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deleteDialog,
          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.deleteDialog) = $event)),
          "max-width": "290"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Löschen bestätigen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Sind Sie sicher, dass Sie dieses Event löschen möchten?")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "blue darken-1",
                      text: "text",
                      onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.deleteDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Abbrechen ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      color: "red",
                      text: "text",
                      onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.deleteEvent(_ctx.selectedEvent.id)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Löschen ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.popupDialog,
          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.popupDialog) = $event)),
          "max-width": "400",
          class: "position-absolute popupDialog-showMore"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "headline" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Termine vom: " + _toDisplayString(_ctx.formatDateToDDMMYYYY(_ctx.popupDate)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, { dense: "" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popupEvents, (event) => {
                          return (_openBlock(), _createBlock(_component_v_list_item, {
                            key: event.id,
                            onClick: ($event: any) => (_ctx.handleEventClick(event))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_v_tooltip, {
                                  activator: "parent",
                                  location: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(event.title), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("span", {
                                    style: _normalizeStyle({ color: event.color }),
                                    class: "ml-1"
                                  }, "●", 4),
                                  _createTextVNode(" " + _toDisplayString(_ctx.formatDateCalPreview(event.start)) + " " + _toDisplayString(event.title), 1)
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}