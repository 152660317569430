
    import { defineComponent, ref, computed, watchEffect } from 'vue';
    import { Applicant, Question, ApplicationData } from '@/types/Application';
    import { Application_Type } from '@/enums/Application';
    import { useStore } from 'vuex';
    import api from "@/api";
    import { JobTypes } from '@/types/Members';
    import { onMounted } from 'vue';

  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
      const formRef = ref<any | null>(null);
      const store = useStore();
      const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";
      const valid = ref(true)
      const isLoading = ref(false);
      const dialog = ref(false);
      const activeTab = ref(0);
      const nameFilter = ref("");
      const sortOrder = ref("Zuletzt hinzugefügt");
      const filterMenu = ref(false);
      const filterStatus = ref({
        pending: true,
        rejected: true,
        approved: true,
      });
      const applicationType = ref(0);
      const applicationData = ref<ApplicationData>({
            newApplication: false,
            id: 0,
            name: '',
            type: 1,
            email: '',
            phonenumber: '',
            birthdate: '',
            status: 'pending',
            info: '',
            jobinterviewDate: '',
            jobinterviewTime: '00:00',
            added: '',
            addToCalendar: false,
            answers: [],
      });


        const selectedJobTypes = ref<Record<number, boolean>>({});
        const jobTypes = ref<JobTypes[]>([]);
        async function fetchJobTypes() {
            try {
                const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                jobTypes.value = response.data;

                // Setzen Sie alle Jobtypen als ausgewählt
                for (const jobType of jobTypes.value) {
                    selectedJobTypes.value[jobType.id] = true;
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                jobTypes.value = [];
            }
        }


        onMounted(async() => {
            await fetchJobTypes();
        })

        const jobStatusTypes = [
            { text: "Ausstehend", value: "pending" },
            { text: "Angenommen", value: "approved" },
            { text: "Abgelehnt", value: "rejected" },
        ];
        const applicants = ref<Applicant[]>([]);
        const questions = ref<Question[]>([]);
    
        const filteredQuestions = computed(() => {
            return questions.value.filter(
            (question) => 
                (Number(question.type) === applicationType.value) || Number(question.type) === Application_Type.Both);
        });
  
        const filteredApplicants = computed(() => {
            let filtered = applicants.value.filter(
                (applicant) =>
                (selectedJobTypes.value[Number(applicant.type)] &&
                    (
                    (filterStatus.value.pending && applicant.status === 'pending') ||
                    (filterStatus.value.rejected && applicant.status === 'rejected') ||
                    (filterStatus.value.approved && applicant.status === 'approved')
                    ) &&
                    (nameFilter.value === "" || applicant.name.toLowerCase().includes(nameFilter.value.toLowerCase())))
            );

            if (sortOrder.value === "Zuletzt hinzugefügt") {
                filtered.sort((a, b) => new Date(b.added).getTime() - new Date(a.added).getTime());
            } else if (sortOrder.value === "Name A-Z") {
                filtered.sort((a, b) => a.name.localeCompare(b.name));
            } else if (sortOrder.value === "Name Z-A") {
                filtered.sort((a, b) => b.name.localeCompare(a.name));
            } else if (sortOrder.value === "Datum aufsteigend") {
                filtered.sort((a, b) => new Date(a.jobinterviewDate).getTime() - new Date(b.jobinterviewDate).getTime());
            } else if (sortOrder.value === "Datum absteigend") {
                filtered.sort((a, b) => new Date(b.jobinterviewDate).getTime() - new Date(a.jobinterviewDate).getTime());
            }

            return filtered;
        });

  
      async function fetchApplicants() {
        try {
          const response = await api.get('application/?action=getApplicants');
          applicants.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
          applicants.value = [];
        }
      }
  
      async function fetchQuestions() {
        try {
          const response = await api.get('application/?action=getQuestions');
          questions.value = response.data;
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
          questions.value = [];
        }
      }
  
      watchEffect(() => {
        fetchApplicants();
        fetchQuestions();
      });
  
      function openApplicationDialog() {
            applicationData.value = {
                newApplication: false,
                name: '',
                id: 0,
                type: 1,
                email: '',
                phonenumber: '',
                birthdate: '',
                status: 'pending',
                info: '',
                jobinterviewDate: '',
                jobinterviewTime: '',
                added: '',
                addToCalendar: false,
                answers: [],
            };
        applicationData.value.newApplication = true;
        dialog.value = true;
      }
  
      function isFormValid() {
        return (
          applicationData.value.name !== '' &&
          applicationData.value.email !== '' &&
          applicationData.value.birthdate !== '' &&
          applicationData.value.jobinterviewDate !== '' &&
          applicationData.value.jobinterviewTime !== ''
        );
      }
  
      function formatDate(date: any) {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${day}.${month}.${year}`;
      }
  
      function openEditDialog(applicant: Applicant) {
        applicationType.value = applicant.type;
        applicationData.value = {
            newApplication: applicant.newApplication,
            name: applicant.name,
            id: applicant.id,
            email: applicant.email,
            type: applicant.type,
            phonenumber: applicant.phonenumber,
            birthdate: applicant.birthdate,
            status: applicant.status,
            info: applicant.info,
            jobinterviewDate: applicant.jobinterviewDate,
            jobinterviewTime: applicant.jobinterviewTime,
            answers: applicant.answers,
            addToCalendar: Boolean(applicant.addToCalendar)
        } as Applicant;
        dialog.value = true;
      }
  
        async function submitApplication() {
            if (formRef.value?.validate()) {
                if (isLoading.value) return;
                isLoading.value = true;
                try {
                    const jwt = store.state.jwt;
                    const response = await api.post("application/?action=submitApplication", applicationData.value);
        
                    await fetchApplicants();
                    await fetchQuestions();
        
                    formRef.value.reset();
                    dialog.value = false;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;
                } finally {
                    isLoading.value = false;
                }
            }
        }
  
      function cardClass(status: string): string {
        if (status === "pending") {
          return "bg-orange-darken-4";
        } else if (status === "rejected") {
          return "bg-red-darken-4";
        } else if (status === "approved") {
          return "bg-green-darken-4";
        }
        return "";
      }


  
      return {
        formRef,
        requiredRule,
        store,
        valid,
        jobStatusTypes,
        jobTypes,
        isLoading,
        dialog,
        activeTab,
        nameFilter,
        sortOrder,
        filterMenu,
        filterStatus,
        applicationType,
        applicationData,
        applicants,
        questions,
        filteredQuestions,
        filteredApplicants,
        openApplicationDialog,
        isFormValid,
        formatDate,
        openEditDialog,
        submitApplication,
        cardClass,
        selectedJobTypes,
        errorSnackbar,
      };
    },
  });
  