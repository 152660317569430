import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
import store from "@/store";
import { isAuthenticated, State, isTokenExpired } from "@/store";
import HomeView from "@/views/HomeView.vue";
import EmployeeView from "@/views/EmployeeView.vue";
import ApplicationView from "@/views/ApplicationView.vue";
import CalendarView from "@/views/CalendarView.vue";
import DocumentView from "@/views/DocumentView.vue";
import FireprotectionView from "@/views/FireprotectionView.vue";
import TemplateView from "@/views/TemplateView.vue";
import TodoView from "@/views/TodoView.vue";
import LoginView from "@/views/LoginView.vue";
import ProfileView from "@/views/ProfileView.vue";
import AdminUserView from "@/views/admin/UserView.vue";
import AdminApplicationView from "@/views/admin/ApplicationQuestionsView.vue";
import AdminRolesView from "@/views/admin/RoleView.vue"
import MapView from "@/views/MapView.vue";
import AdminSettingsView from "@/views/admin/SettingsView.vue";
import AdminEmployeeView from "@/views/admin/EmployeeView.vue";
import AdminMapView from "@/views/admin/MapView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: LoginView,
    },
    {
        path: "/home",
        name: "home",
        component: HomeView,
        meta: { requiresAuth: true },
    },
    {
        path: "/employee",
        name: "employee",
        component: EmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_EMPLOYEE'  },
    },
    {
        path: "/application",
        name: "application",
        component: ApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_APPLICATION'  },
    },
    {
        path: "/calendar",
        name: "calendar",
        component: CalendarView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_CALENDAR'  },
    },
    {
        path: "/document",
        name: "documents",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT', docType: 'document'  },
    },
    {
        path: "/training",
        name: "trainings",
        component: DocumentView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_DOCUMENT_TRAINING', docType: 'training'  },
    }, 
    {
        path: "/template",
        name: "template",
        component: TemplateView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TEMPLATE'  },
    },
    {
        path: "/todo",
        name: "todo",
        component: TodoView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_TODO'  },
    },
    {
        path: '/profile',
        name: "profile",
        component: ProfileView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_ACCOUNT'  },
    },
    {
        path: '/admin/applicationquestions',
        name: "admin_applicationquestions",
        component: AdminApplicationView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_APPLICATION'  },
    },
    {
        path: '/admin/users',
        name: "admin_users",
        component: AdminUserView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_USERS'  },
    },
    {
        path: '/admin/roles',
        name: "admin_roles",
        component: AdminRolesView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_ROLES'  },
    },
    {
        path: '/admin/settings',
        name: "admin_settings",
        component: AdminSettingsView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_SETTINGS'  },
    },
    {
        path: '/admin/employees',
        name: "admin_employee",
        component: AdminEmployeeView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_EMPLOYEE'  },
    },
    {
        path: '/admin/map',
        name: "admin_map",
        component: AdminMapView,
        meta: { requiresAuth: true, requiredPermission: 'ADMIN_VIEW_MAP'  },
    },
    {
        path: '/map',
        name: "map",
        component: MapView,
        meta: { requiresAuth: true, requiredPermission: 'VIEW_MAP'  },
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

import api from "@/api";

router.beforeEach(async (to, from, next) => {
    const isUserAuthenticated = isAuthenticated(store.state as State);
    const isExpired = isTokenExpired(store.state as State);

    if (isUserAuthenticated) {
        if (isExpired) {
          // Benutzer abmelden
            await store.dispatch("logout");
            // Benutzer zur Login-Seite umleiten
            if (to.name !== "login") {
                next({ name: "login" });
            } else {
                next();
            }
        } else {
          // Benutzer ist eingeloggt und versucht, auf die Login-Seite zuzugreifen,
          // leiten Sie ihn zur Startseite weiter
            if (to.name === "login") {
                next({ name: "home" });
            } else {
                try {
                const response = await api.post("account/?action=checkUser");
                const { permissions, banned } = response.data;
        
                if (banned) {
                    store.dispatch("logout");
                    next({ name: "login" });
                } else {
                    store.commit("setPermissions", permissions);
        
                    if (to.meta.requiredPermission && (!store.state.user.permissions || (!store.state.user.permissions.includes(to.meta.requiredPermission) && !store.state.user.permissions.includes('ALL_PERMISSIONS')))) {
                    next({ name: "home" });
                    } else {
                    next();
                    }
                }
                } catch (error) {
                console.error("Error fetching permissions:", error);
                next();
                }
            }
        }
    } else {
        if (to.meta.requiresAuth) {
          next({ name: "login" });
        } else {
          next();
        }
    }
});


export default router;
