
import api from "@/api";
import { defineComponent, ref, onMounted, computed } from "vue";
import { Event } from "@/types/Calendar";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

export default defineComponent({
  components: {
    VueCal,
  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const formRef = ref<any | null>(null);
        const users = ref<Array<{ id: number; username: string }>>([]);
        const calendar = ref<any | null>(null);
        const deleteDialog = ref(false);

        async function deleteEvent(idEvent: number) {
            try {
                const response = await api.post("calendar/?action=deleteEvent", {
                    id: idEvent,
                });
                
                deleteDialog.value = false;
                eventDialog.value = false;
                await fetchEvents();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        onMounted(async () => {
            await fetchUsers();
            await fetchEvents();
            applyFilters();
        });

        async function fetchUsers() {
            try {
                const response = await api.get("user/?action=getUsers");
                users.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
        }
        }

        const requiredRule = (value: string) => !!value || "Dieses Feld ist erforderlich.";

        const predefinedColors = [["#008000"], ["#FFA500"], ["#FF0000"]];
        const events = ref<Event[]>([]);
        const eventDialog = ref(false);
        const newEventDialog = ref(false);
        const originalEvents = ref<Event[]>([]);
        const showAllEvents = ref(false);
        const showMore = ref(false);
        const clickShowMore = ref(false);
        const eventsDayIndices = ref<{ [date: string]: { [index: string]: number } }>({});
        const popupDialog = ref(false);
        const popupEvents = ref<Array<{
            id: number;
            title: string;
            content: string;
            start: string;
            end: string;
            color: string;
            contentFull: string;
            assigned_to: string[];
        }>>([]);
        const popupDate = ref("");
        const popupPosition = ref({ x: 0, y: 0 });
        const filterOptions = ref({
        assignedTo: [] as number[],
            });
        const recurring = [
            { text: "Nicht", value: "" },
            { text: "Täglich", value: "day" },
            { text: "Monatlich", value: "month" },
        ];
        const selectedEvent = ref({
            id: 0,
            title: "",
            content: "",
            start: "",
            end: "",
            color: "",
            contentFull: "",
            assigned_to: [],
            recurring: "",
        });
        const newEvent = ref({
            title: "",
            content: "",
            start: "",
            end: "",
            color: "",
            contentFull: "",
            assigned_to: [],
            recurring: "",
        });

        // methods
        async function fetchEvents() {
        try {
            const response = await api.get('calendar/?action=getEvents');
            originalEvents.value = response.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
            events.value = [...originalEvents.value]; // Setzen Sie die Ereignisliste auf den ursprünglichen Zustand zurück
            popupEvents.value = events.value.filter((event) => {
                return formatDate(event.start, true, true).split("T")[0] === popupDate.value;
            });
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
        }
        }

        function closePopup() {
            popupDialog.value = false;
        }

        function handleShowMore(eventDate: string) {
            popupEvents.value = events.value.filter((event) => {
                return formatDate(event.start, true, true).split("T")[0] === eventDate;
            });
            popupDate.value = formatDate(eventDate, false, true);
            popupDialog.value = true;
        }

        function getEventDayIndex(event: Event) {
            const eventDate = formatDate(event.start, true).split("T")[0];
            if (!eventsDayIndices.value[eventDate]) {
                eventsDayIndices.value[eventDate] = {};
            }

            if (eventsDayIndices.value[eventDate][event.id] === undefined) {
                const visibleEvents = events.value.filter(
                    (e) => formatDate(e.start, true).split("T")[0] === eventDate
                );
                visibleEvents.forEach((e, index) => {
                    eventsDayIndices.value[eventDate][e.id] = index;
                });
            }

            return eventsDayIndices.value[eventDate][event.id];
        }

        function isFormValid(exist: boolean) {
            if(exist) return selectedEvent.value.title !== ''
            else return newEvent.value.title !== ''
        }

        function getEventIndex(event: Event) {
            return events.value.findIndex(e => e.id === event.id);
        }

        function handleEventClick(event: any) {
            selectedEvent.value = {
                ...event,
                assigned_to: event.assigned_to.filter((user: any) => user !== ""),
            };

            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            selectedEvent.value.start = new Date(new Date(event.start).getTime() - timezoneOffset).toISOString().slice(0, -1);
            selectedEvent.value.end = new Date(new Date(event.end).getTime() - timezoneOffset).toISOString().slice(0, -1);
            eventDialog.value = true;
        }

        function handleCellClick(cell: any) {
            const startDate = formatDate(cell, true);
            const endDate = formatDate(cell, false);

            newEvent.value = {
                title: "",
                content: "",
                start: startDate,
                end: endDate,
                color: "",
                contentFull: "",
                assigned_to: [],
                recurring: "",
            };
            newEventDialog.value = true;
        }

        function applyFilters() {
            if (filterOptions.value.assignedTo.length > 0) {
                events.value = originalEvents.value.filter((event) =>
                    event.assigned_to.some((user) => filterOptions.value.assignedTo.includes(Number(user)))
                );
            } else {
                events.value = [...originalEvents.value]; // Setzen Sie die Ereignisliste auf den ursprünglichen Zustand zurück, wenn keine Filter angewendet sind
            }
        }

        function formatDate(dateInput: string | Date, isStart: boolean, onlyDate = false) {
            const date = new Date(dateInput);
            if (!isStart) {
                date.setHours(date.getHours() + 1);
            }

            const timezoneOffset = date.getTimezoneOffset() * 60000;
            const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);

            const [datePart, timePart] = localISOTime.split('T');
            let [hour, minute] = timePart.split(':');

            // Runde die Minuten auf die nächsten 15-Minuten-Schritte
            const roundedMinute = Math.ceil(parseInt(minute) / 15) * 15;
            if (roundedMinute === 60) {
                // Wenn die gerundeten Minuten 60 sind, erhöhe die Stunden um 1 und setze die Minuten auf 0
                hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                minute = "00";
            } else {
                minute = roundedMinute.toString().padStart(2, "0");
            }

            return onlyDate ? datePart : `${datePart}T${hour}:${minute}`;
        }

        function formatDateToDDMMYYYY(datePart: any) {
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        }

        function formatDateCalPreview(dateInput: string | Date) {
            const date = new Date(dateInput);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        }

        function handleEventDragEnd(event: any) {
            const updatedEvent = { ...event.event }; // Use event.event instead of event

            if (updatedEvent.assigned_to != undefined) {
                // Extrahieren Sie die Uhrzeit aus dem ursprünglichen Start- und Enddatum
                const originalStart = new Date(event.originalEvent.start);
                const originalEnd = new Date(event.originalEvent.end);

                // Kombinieren Sie das neue Datum mit der ursprünglichen Uhrzeit
                const newStartDate = new Date(event.event.start);
                const newEndDate = new Date(event.event.end);

                newStartDate.setHours(originalStart.getHours(), originalStart.getMinutes());
                newEndDate.setHours(originalEnd.getHours(), originalEnd.getMinutes());

                const timezoneOffset = new Date().getTimezoneOffset() * 60000;
                updatedEvent.start = new Date(new Date(newStartDate).getTime() - timezoneOffset).toISOString().slice(0, -1);
                updatedEvent.end = new Date(new Date(newEndDate).getTime() - timezoneOffset).toISOString().slice(0, -1);

                updatedEvent.assigned_to = updatedEvent.assigned_to.filter((user: any) => user !== ""); // Convert Proxy object to array
                updateEventAfterDrag(updatedEvent);
            }
        }

        async function updateEventAfterDrag(updatedEvent: Event) {
            try {
            console.log("Update event after drag request data:", updatedEvent); // Log the request data for updateEventAfterDrag()

            const response = await api.put("calendar/?action=updateEvent", updatedEvent);

            if (response.status === 200) {
                fetchEvents();
            }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        async function updateEvent() {
            try {
                const requestData = {
                ...selectedEvent.value,
                assigned_to: selectedEvent.value.assigned_to.filter((user: any) => user !== ""),
            };

            console.log("Update event request data:", requestData); // Log the request data for updateEvent()

            const response = await api.put("calendar/?action=updateEvent", requestData);

            if (response.status === 200) {
                eventDialog.value = false;
                fetchEvents();
            }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        async function createEvent() {
            try {
                const response = await api.post("calendar/?action=createEvent", {
                    ...newEvent.value,
                    assigned_to: newEvent.value.assigned_to.filter((user: any) => user !== ""),
                });
                if (response.status === 200) {
                    newEventDialog.value = false;
                    fetchEvents();
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }

        const eventsPerDay = computed(() => {
            const eventsByDate: { [date: string]: number } = {};

            events.value.forEach(event => {
            const date = formatDate(new Date(event.start), true).split("T")[0];

            if (eventsByDate[date]) {
                eventsByDate[date]++;
            } else {
                eventsByDate[date] = 1;
            }
            });

            return eventsByDate;
        });

        return {
            formRef,
            requiredRule,
            fetchUsers,
            users,
            calendar,
            predefinedColors,
            events,
            eventDialog,
            newEventDialog,
            originalEvents,
            showAllEvents,
            showMore,
            clickShowMore,
            eventsDayIndices,
            popupDialog,
            popupEvents,
            popupDate,
            popupPosition,
            filterOptions,
            recurring,
            selectedEvent,
            newEvent,
            fetchEvents,
            closePopup,
            handleShowMore,
            getEventDayIndex,
            isFormValid,
            getEventIndex,
            handleEventClick,
            handleCellClick,
            applyFilters,
            formatDate,
            formatDateToDDMMYYYY,
            formatDateCalPreview,
            handleEventDragEnd,
            updateEventAfterDrag,
            updateEvent,
            createEvent,
            eventsPerDay,
            errorSnackbar,
            deleteDialog,
            deleteEvent,
        };
    },
});
