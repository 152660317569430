// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { mdi } from 'vuetify/iconsets/mdi'
import { VDataTable } from 'vuetify/labs/VDataTable'


// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    components: {
        VDataTable,
    },
    theme: {
      defaultTheme: 'dark'
    },
    icons: {
        defaultSet: 'mdi',
        sets: {
          mdi,
        }
    },
})