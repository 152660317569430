import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import vuetify from './plugins/vuetify'
import axios from 'axios';
import { loadFonts } from './plugins/webfontloader'
import Draggable from "vuedraggable";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ErrorSnackbar from '@/components/ErrorSnackbar.vue';

loadFonts()

store.dispatch('restoreAuthentication');


const lsfd = createApp(App);

lsfd.directive('checked', {
    beforeMount(el, binding) {
      el.checked = !!binding.value;
    },
    updated(el, binding) {
      if (binding.value !== binding.oldValue) {
        el.checked = !!binding.value;
      }
    },
  });
  
lsfd.config.globalProperties.$axios = axios;
lsfd.use(store)
lsfd.use(router)
lsfd.use(vuetify)
lsfd.use(CKEditor)
lsfd.component('error-snackbar', ErrorSnackbar);
lsfd.mount('#app')
