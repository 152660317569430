import axios, { InternalAxiosRequestConfig } from "axios";
import store from "@/store"; // Stellen Sie sicher, dass der Pfad zu Ihrer store/index.ts-Datei korrekt ist

const baseURL = process.env.NODE_ENV === 'development' ? 'https://api.areav.de/' : '/restapi/';

//https://api.areav.de/
//https://api-casa.kyuubiddragon.net
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = store.state.jwt;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default api;
