
  import { defineComponent, ref, onMounted, computed } from "vue";
  import api from "@/api";
  import { TodoList, Todo } from "@/types/Todo";


  export default defineComponent({
    name: "TodoView",
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const todoLists = ref<TodoList[]>([]);
        const todos = ref<Todo[]>([]);
        const selectedList = ref<TodoList | null>(null);
        const selectedTodo = ref<Todo | null>(null);
        const newListName = ref<string>("");
        const selectedParentFolder = ref<number | null>(null);
        const showCreateListDialog = ref<boolean>(false);
        const newTodoTitle = ref("");
        const showCompletedTodos = ref(false);
        const showCompletedTodoCheckboxes = ref(false);
        const newSubGroup = ref("");

        const editedValue = ref('');
        const editingField = ref({ type: '', id: -1 });

        const selectedAssignedUsers = computed(() => {
            if(selectedTodo.value?.is_global){
                return usersWithPermissions;
            }else if (selectedTodo.value?.assigned_users) {
                return usersWithPermissions.value.filter(user => selectedTodo?.value!.assigned_users?.includes(user.id));
            } else {
                return [];
            }
        });
        
        const startEditingField = (fieldType: string, fieldId: number) => {
            editingField.value = { type: fieldType, id: fieldId };
            if (fieldType === 'listName' && selectedList.value) {
                editedValue.value = selectedList.value.name;
            }else if (fieldType === 'todoTitle' && selectedTodo.value) {
                editedValue.value = selectedTodo.value.title;
            }else if (fieldType === 'todoSubDescription' && selectedList.value) {
                editedValue.value = selectedList.value.name;
            } else if (fieldType === 'todoCheckboxEdit' && selectedTodo.value && selectedTodo.value.checkboxes) {
                const checkbox = selectedTodo.value.checkboxes.find((c) => c.id === fieldId);
                if (checkbox) {
                    editedValue.value = checkbox.title;
                }
            }
            // Füge hier weitere Bedingungen für andere Feldtypen hinzu
        };

        const cancelEditingField = () => {
            editingField.value = { type: '', id: -1 };
        };

        async function updateField() {
            if (editedValue.value.trim() === '') return;

            if (editingField.value.type === 'listName' && selectedList.value) {
                try {
                    await api.post('todo/?action=updateTodoListName', {
                        id: selectedList.value.id,
                        name: editedValue.value,
                    });
                    selectedList.value.name = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            } else if (editingField.value.type === 'todoTitle' && selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoTitle', {
                        id: selectedTodo.value.id,
                        title: editedValue.value,
                    });
                    selectedTodo.value.title = editedValue.value;
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }else if (editingField.value.type === 'todoCheckboxEdit' && selectedTodo.value && selectedTodo.value.checkboxes) {
                const checkboxIndex = selectedTodo.value.checkboxes.findIndex(
                    (c) => c.id === editingField.value.id
                );
                if (checkboxIndex > -1) {
                    try {
                        await api.post("todo/?action=updateTodoCheckboxText", {
                            id: editingField.value.id,
                            title: editedValue.value,
                        });
                        selectedTodo.value.checkboxes[checkboxIndex].title = editedValue.value;
                    } catch (error) {
                        errorSnackbar.value.message = error.response.data.error;
                        errorSnackbar.value.visible  = true;	
                    }
                }
            }

            // Füge hier weitere Bedingungen für andere Feldtypen hinzu

            cancelEditingField(); // Schließt das bearbeitete Feld nach der Aktualisierung
        }

        const usersWithPermissions = ref<Array<{ id: number; username: string }>>([]);
        async function fetchUsers(listId: number) {
            try {
                const response = await api.post(`todo/?action=getUsersWithTodoPermissions`, {
                    list_id: listId,
                });
                usersWithPermissions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        const menu = ref(false);
        const newTodoCheckbox = ref('');

        async function updateImportance() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoImportance', {
                        id: selectedTodo.value.id,
                        importance: selectedTodo.value.importance,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateDueDate() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoDueDate', {
                        id: selectedTodo.value.id,
                        due_date: selectedTodo.value.due_date,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateCheckbox(id:number, checked:boolean) {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateCheckbox', {
                        id: id,
                        completed: checked,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function createTodoCheckbox() {
            if (selectedTodo.value && newTodoCheckbox.value) {
                try {
                    const response = await api.post('todo/?action=createTodoCheckbox', {
                        id: selectedTodo.value.id,
                        name: newTodoCheckbox.value,
                    });
                    const newCheckbox = {
                        id: response.data.checkbox_id,
                        todo_id: response.data.list_id,
                        title: newTodoCheckbox.value,
                        completed: false,
                    };
                    if (!selectedTodo.value.checkboxes) {
                        selectedTodo.value.checkboxes = [];
                    }
                    selectedTodo.value.checkboxes.push(newCheckbox);
                    newTodoCheckbox.value = "";
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }
        async function updateAssignedUser() {
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoAssignedUser', {
                        id: selectedTodo.value.id,
                        assigned_users: selectedTodo.value.assigned_users?.filter((user: any) => user !== ""),
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }

        async function updateDescription() {
            // Logik zum Aktualisieren des zugewiesenen Benutzers
            if (selectedTodo.value) {
                try {
                    await api.post('todo/?action=updateTodoDescription', {
                        id: selectedTodo.value.id,
                        description: selectedTodo.value.description,
                    });
                } catch (error) {
                    errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
                }
            }
        }



        const deleteSubListDialog = ref({
            show: false,
            subList: null as TodoList | null,
        });

        function showDeleteSubListDialog(subList: TodoList) {
            deleteSubListDialog.value.show = true;
            deleteSubListDialog.value.subList = subList;
        }

        async function deleteSubList() {
            if (!deleteSubListDialog.value.subList) return;

            try {
                await api.post("todo/?action=deleteTodoList", {
                    list_id: deleteSubListDialog.value.subList.id,
                });
                deleteSubListDialog.value.show = false;
                deleteSubListDialog.value.subList = null;
                await fetchTodoLists();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                    errorSnackbar.value.visible  = true;	
            }
        }



        const folderOptions = computed(() => {
            return [{ text: "Neuer Ordner", value: null as (number | null) }].concat(
                todoLists.value.map((list) => ({
                text: list.name,
                value: list.id,
                }))
            );
        });

        const unfinishedTodos = computed(() => {
            let sortedTodos = todos.value.slice().sort((a, b) => {
                return (a.completed ? 1 : 0) - (b.completed ? 1 : 0);
            });

            if (showCompletedTodos.value) {
                return sortedTodos;
            } else {
                return sortedTodos.filter((todo) => !todo.completed);
            }
        });



        const formatDateToDDMMYYYY = (datePart: any) => {
            if(datePart == null) return;
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        };

        const getDueDateStyle = (dueDate: any) => {
            if(dueDate == null) return;
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            const todoDueDate = new Date(dueDate);
            todoDueDate.setHours(0, 0, 0, 0);

            if (todoDueDate.getTime() === today.getTime()) {
                return { backgroundColor: '#e862005e' };
            } else if (todoDueDate.getTime() <= yesterday.getTime()) {
                return { backgroundColor: '#ae00005e' };
            } else {
                return {};
            }
        };

        const unfinishedTodoCheckboxes = computed(() => {
            if (showCompletedTodoCheckboxes.value) {
                return selectedTodo.value?.checkboxes;
            } else {
                return selectedTodo.value && selectedTodo.value.checkboxes
                    ? selectedTodo.value.checkboxes.filter((checkbox) => !checkbox.completed)
                    : [];
            }
        });



        // Add this function
        async function updateTodoCompletionStatus(todo: Todo) {
            try {
                await api.post("todo/?action=updateTodoCompletionStatus", {
                    id: todo.id,
                    completed: todo.completed,
                });
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        // Add this function
        function toggleCompletedTodos() {
            showCompletedTodos.value = !showCompletedTodos.value;
        }



        const isActiveList = (list: TodoList) => {
            return selectedList.value && selectedList.value.id === list.id;
        };
        const isActiveTodo = (todo: Todo) => {
            return selectedTodo.value && selectedTodo.value.id === todo.id;
        };
        const isSelectedList = () => {
            return selectedList.value !== null;
        };

        const topLevelTodoLists = computed(() => {
            return todoLists.value.filter((list) => !list.parent_list_id);
        });

        const getSubLists = (parentId: number) => {
            return todoLists.value.filter((list) => list.parent_list_id === parentId);
        };



        onMounted(async () => {
            await fetchTodoLists();
        });

        async function fetchTodoLists() {
            try {
                const response = await api.get("todo/?action=getTodoLists");
                todoLists.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchTodos(listId: number) {
            try {
                const response = await api.post(`todo/?action=getTodos`, {
                    list_id: listId,
                });
                todos.value = response.data.map((todo: Todo) => ({
                    ...todo,
                    completed: !!todo.completed,
                    assigned_users:  todo.assigned_users ? todo.assigned_users.map((userId: number) => Number(userId)) : [],
                    checkboxes: todo.checkboxes ? todo.checkboxes.map((checkbox) => ({
                        ...checkbox,
                        completed: !!checkbox.completed,
                    })) : [],
                }));
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }


        function selectList(list: TodoList) {
            selectedList.value = list;
            fetchTodos(list.id);
            fetchUsers(list.id);
        }

        function selectTodo(todo: Todo) {
         selectedTodo.value = todo;
        }

        async function createList() {
            try {
                await api.post("todo/?action=createTodoList", {
                    name: newListName.value,
                });
                await fetchTodoLists();
                showCreateListDialog.value = false;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function createTodo() {
            if (newTodoTitle.value.trim() === "" || !selectedList.value) return;

            try {
                await api.post("todo/?action=createTodo", {
                    title: newTodoTitle.value,
                    list_id: selectedList.value.id,
                });
                newTodoTitle.value = "";
                await fetchTodos(selectedList.value.id);
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function createSubList(parentListId: number) {
            try {
                if(newSubGroup.value == "") return;
                await api.post("todo/?action=createTodoList", {
                    name: newSubGroup.value,
                    parent_list_id: parentListId,
                });
                newSubGroup.value = "";
                await fetchTodoLists();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

  
        return {
            todoLists,
            todos,
            selectedList,
            selectedTodo,
            selectList,
            selectTodo,
            isActiveList,
            isActiveTodo,
            newTodoTitle,
            createTodo,
            isSelectedList,
            folderOptions,
            selectedParentFolder,
            createList,
            newListName,
            showCreateListDialog,
            createSubList,
            topLevelTodoLists,
            getSubLists,
            showCompletedTodos,
            unfinishedTodos,
            updateTodoCompletionStatus,
            toggleCompletedTodos,
            newSubGroup,
            deleteSubListDialog,
            showDeleteSubListDialog,
            deleteSubList,
            startEditingField, cancelEditingField, updateField, editedValue, editingField,
            menu,
            newTodoCheckbox,
            updateImportance,
            updateDueDate,
            updateCheckbox,
            createTodoCheckbox,
            updateAssignedUser,
            usersWithPermissions,
            updateDescription,
            unfinishedTodoCheckboxes,
            showCompletedTodoCheckboxes,
            selectedAssignedUsers,
            formatDateToDDMMYYYY,
            getDueDateStyle,
            errorSnackbar,
        };
    },
  });

  