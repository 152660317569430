
  import { defineComponent, ref } from "vue";
  import api from "@/api";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import bcrypt from "bcryptjs";
  
  export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();
        
        const autoLogin = ref(false);
        const username = ref("");
        const password = ref("");
        const required = (value: string) => !!value || "This field is required.";
        const errorSnackbar = ref<any>({ visible: false, message: "" });
  
        const submit = async () => {
        // You can add form validation here if needed
            try{
                const response = await api.post("login/", `action=login&username=${encodeURIComponent(username.value)}&password=${encodeURIComponent(password.value)}&remember_me=${autoLogin.value}`, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });

                if (response.data && response.data.jwt) {
                    store.dispatch("authenticate", {
                        user: response.data.user,
                        jwt: response.data.jwt,
                    });
                    // Redirect to a protected page or the main page after successful login
                    router.push({ name: "home" });
                } else {
                    // Handle unsuccessful login attempts (e.g., display an error message)
                    errorSnackbar.value.message = response.data.error;
                    errorSnackbar.value.visible  = true;
                }
            }catch(error){
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
        const forgotPassword = () => {
            // Führen Sie die gewünschte Aktion aus, z.B. eine Benachrichtigung anzeigen oder zur Passwort-vergessen-Seite navigieren
            console.log("forgot pw");
        };

  
        return {
            username,
            password,
            required,
            submit,
            autoLogin,
            forgotPassword,
            errorSnackbar,
        };
    },
  });
