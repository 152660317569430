
  import { defineComponent, ref, onMounted } from 'vue';
  import draggable from 'vuedraggable';
  import { Question } from '@/types/Application';
  import api from '@/api';
  import { JobTypes } from '@/types/Members';
  
  export default defineComponent({
    components: {
        draggable,
    },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const dialog = ref(false);
        const sortingDialog = ref(false);
        const questions = ref<Question[]>([]);
        const newQuestion = ref('');
        const filteredQuestions = ref<Question[]>([]);
        const currentDocumentId = ref<number | null>(null);
        const deleteConfirmationDialog = ref(false);
        const questionToDelete = ref<number | null>(null);

        const documentTypes = ref<JobTypes[]>([]);
        async function fetchJobTypes() {
            try {
                const response = await api.get('employee/index_fetch.php?action=getJobTypes');
                documentTypes.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                documentTypes.value = [];
            }
        }




        function filterQuestions(id: number) {
            // Filtern Sie die Fragen basierend auf der "documentId"
            filteredQuestions.value = questions.value.filter((question) => question.type === id);
        }
    
        function openApplicantQuestionEditor(documentId: number) {
            currentDocumentId.value = documentId;
            filterQuestions(documentId); // Filtern Sie die Fragen basierend auf der gewählten "documentId"
            dialog.value = true;
        }

        function openSortingDialog(documentId: number) {
            filterQuestions(documentId); // Filtern Sie die Fragen basierend auf der gewählten "documentId"
            sortingDialog.value = true;
        }
    
        function openDeleteConfirmationDialog(questionId: number) {
            questionToDelete.value = questionId;
            deleteConfirmationDialog.value = true;
        }

        const deleteQuestion = async (questionId: number) => {
            try {
                const response = await api.post("admin/application/?action=deleteQuestion", {
                    id: questionId
                });

                if (response.data.success) {
                    console.log("Question deleted successfully");
                    filteredQuestions.value = filteredQuestions.value.filter((question) => question.id !== questionId);
                } else {
                    console.error("Failed to delete question:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }


        const saveQuestions = async () => {
            try {
                console.log("Sending questions data:", filteredQuestions.value);
                const response = await api.post("admin/application/?action=saveQuestions", JSON.stringify(filteredQuestions.value));

                if (response.data.success) {
                    console.log("Question saved successfully");
                    fetchQuestions();
                } else {
                console.error("Failed to save question:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
            dialog.value = false;
        };

        const saveQuestionsSort = async () => {
            try {
                console.log("Sending category data:", filteredQuestions.value);
                const response = await api.post("admin/application/?action=saveQuestionSorting", JSON.stringify(filteredQuestions.value));

                if (response.data.success) {
                console.log("Question sorting saved successfully");
                } else {
                console.error("Failed to save question sorting:", response.data.error);
                }
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }

            sortingDialog.value = false;
        };



        async function fetchQuestions() {
            try {
            const response = await api.get('admin/application/?action=getQuestions');
                questions.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                questions.value = [];
            }
        }

        async function addQuestion() {
            if (newQuestion.value.trim() && currentDocumentId.value) {
                const success = await saveNewQuestion(newQuestion.value, currentDocumentId.value);
                if (success) {
                newQuestion.value = "";
                }
            }
        }



        async function saveNewQuestion(newQuestion: string, documentId: number) {
            try {
                const response = await api.post("admin/application/?action=addQuestion", {
                    question: newQuestion,
                    type: documentId,
                    sort_order: filteredQuestions.value.length,
                });

                filteredQuestions.value.push(response.data);

                return true;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
                return false;
            }
        }



        onMounted(async () => {
            await fetchJobTypes();
            await fetchQuestions();
        })

        return {
            documentTypes,
            dialog,
            sortingDialog,
            questions,
            newQuestion,
            openApplicantQuestionEditor,
            openDeleteConfirmationDialog,
            deleteQuestion,
            saveQuestions,
            filteredQuestions,
            openSortingDialog,
            addQuestion,
            saveQuestionsSort,
            deleteConfirmationDialog,
            questionToDelete,
            errorSnackbar,
        };

    },
});
