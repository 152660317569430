import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.dialog) = $event)),
    persistent: "",
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(!_ctx.newEmployer ? 'Mitarbeiter bearbeiten' : 'Mitarbeiter hinzufügen'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { fluid: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.name,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employee.name) = $event)),
                            label: "Name*",
                            outlined: "",
                            required: "",
                            rules: [_ctx.requiredRule]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.personalid,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.employee.personalid) = $event)),
                            label: "Ausweis-ID",
                            outlined: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.birthdate,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employee.birthdate) = $event)),
                            label: "Geburtstag",
                            outlined: "",
                            type: "date",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.phonenumber,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employee.phonenumber) = $event)),
                            label: "Telefonnummer",
                            outlined: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.mail,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.employee.mail) = $event)),
                            label: "Venture Mail",
                            outlined: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.servicenumber,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.employee.servicenumber) = $event)),
                            label: "Dienstnummer*",
                            outlined: "",
                            required: "",
                            rules: [_ctx.requiredRule]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.entrydate,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.employee.entrydate) = $event)),
                            label: "Eintrittsdatum*",
                            outlined: "",
                            type: "date",
                            required: "",
                            rules: [_ctx.requiredRule]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.sidejob,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.employee.sidejob) = $event)),
                            label: "Nebenjob",
                            outlined: "",
                            type: "text"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.bankaccount,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.employee.bankaccount) = $event)),
                            label: "Kontonummer",
                            outlined: "",
                            type: "text"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.employee.company,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.employee.company) = $event)),
                            items: _ctx.$props.companies,
                            "item-value": "id",
                            "item-title": "name",
                            label: "Company",
                            outlined: "",
                            multiple: "",
                            chips: ""
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.employee.department,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.employee.department) = $event)),
                            items: _ctx.$props.departments,
                            "item-value": "id",
                            "item-title": "name",
                            label: "Abteilung",
                            outlined: "",
                            multiple: "",
                            chips: ""
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.employee.rankId,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.employee.rankId) = $event)),
                            items: _ctx.$props.ranks,
                            "item-value": "id",
                            "item-title": "name",
                            label: "Rang*",
                            rules: [_ctx.requiredRule],
                            outlined: "",
                            chips: "",
                            required: ""
                          }, null, 8, ["modelValue", "items", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            modelValue: _ctx.employee.license,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.employee.license) = $event)),
                            items: _ctx.$props.licenses,
                            "item-value": "id",
                            "item-title": "name",
                            label: "Lizenz",
                            outlined: "",
                            multiple: "",
                            chips: ""
                          }, null, 8, ["modelValue", "items"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_checkbox, {
                            modelValue: _ctx.employee.is_terminated,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.employee.is_terminated) = $event)),
                            label: "Gekündigt",
                            outlined: "",
                            onChange: _ctx.updateLeaveDate
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.employee.leavedate,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.employee.leavedate) = $event)),
                            label: "Austrittsdatum",
                            outlined: "",
                            type: "date",
                            disabled: !_ctx.employee.is_terminated
                          }, null, 8, ["modelValue", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.dialog = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Abbrechen")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                color: "blue darken-1",
                text: "",
                onClick: _ctx.submitEmployee
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!_ctx.newEmployer ? 'Änderungen speichern' : 'Speichern'), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}