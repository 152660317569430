import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onDblclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_snackbar = _resolveComponent("error-snackbar")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_EmployeeForm = _resolveComponent("EmployeeForm")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_MemberCard = _resolveComponent("MemberCard")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_error_snackbar, {
      snackbar: _ctx.errorSnackbar,
      "onUpdate:snackbar": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.errorSnackbar) = $event))
    }, null, 8, ["snackbar"]),
    _createVNode(_component_v_container, { fluid: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openEditForm(null)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Mitarbeiter hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _ctx.openAddRankDialog
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Rang hinzufügen")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_EmployeeForm, {
                  onUpdateEmployeeList: _ctx.fetchEmployee,
                  companies: _ctx.companies,
                  departments: _ctx.departments,
                  ranks: _ctx.ranks,
                  licenses: _ctx.licenses,
                  ref: "employeeFormRef"
                }, null, 8, ["onUpdateEmployeeList", "companies", "departments", "ranks", "licenses"]),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSortingModal = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ränge sortieren")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  class: "ml-5",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPreview = !_ctx.showPreview))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Vorlage " + _toDisplayString(_ctx.showPreview ? "deaktivieren" : "aktivieren"), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_spacer)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_switch, {
                  modelValue: _ctx.showIsTerminated,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showIsTerminated) = $event)),
                  "hide-details": "",
                  inset: "",
                  label: `Gekündigt: ${_ctx.showIsTerminated.toString()}`,
                  class: "ml-auto align-self-center",
                  style: {"position":"absolute","right":"30px"}
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.search) = $event)),
                  label: "Search",
                  dark: "",
                  outlined: "",
                  "hide-details": "",
                  onInput: _ctx.updateFilteredItems
                }, null, 8, ["modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  modelValue: _ctx.selectedDepartment,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedDepartment) = $event)),
                  items: _ctx.JobTypes,
                  "item-title": "name",
                  "item-value": "id",
                  label: "Department",
                  dark: "",
                  outlined: "",
                  "hide-details": "",
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.filterByDepartment(_ctx.selectedDepartment)))
                }, null, 8, ["modelValue", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranksWithEmployee, (rank, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  sm: "6",
                  md: "4",
                  lg: "3"
                }, {
                  default: _withCtx(() => [
                    (_ctx.editingField.type !== 'rankName' || _ctx.editingField.id !== (rank ? rank.id : -1))
                      ? (_openBlock(), _createElementBlock("h2", {
                          key: 0,
                          onDblclick: ($event: any) => (_ctx.startEditingField('rankName', rank ? rank.id : -1))
                        }, [
                          _createTextVNode(_toDisplayString(rank ? rank.name : '') + " ", 1),
                          (rank.employees.length == 0)
                            ? (_openBlock(), _createBlock(_component_v_btn, {
                                key: 0,
                                icon: "",
                                size: "small",
                                onClick: ($event: any) => (_ctx.openDeleteRankDialog(rank)),
                                class: "ml-2"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_icon, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("mdi-close")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            : _createCommentVNode("", true)
                        ], 40, _hoisted_1))
                      : (_openBlock(), _createBlock(_component_v_text_field, {
                          key: 1,
                          modelValue: _ctx.editedValue,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedValue) = $event)),
                          "single-line": "",
                          onKeydown: [
                            _withKeys(($event: any) => (_ctx.updateField(rank ? rank.id : -1)), ["enter"]),
                            _withKeys(_ctx.cancelEditingField, ["esc"])
                          ],
                          onBlur: _ctx.cancelEditingField,
                          ref_for: true,
                          ref: "editField",
                          class: "mt-5 mr-5"
                        }, null, 8, ["modelValue", "onKeydown", "onBlur"]))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rank.employees.filter(item => item.name.toLowerCase().includes(_ctx.search.toLowerCase())), (employee, employeeIndex) => {
                  return (_openBlock(), _createBlock(_component_MemberCard, {
                    key: employeeIndex,
                    member: employee,
                    logoURL: rank.rankImage,
                    companies: _ctx.companies,
                    departments: _ctx.departments,
                    ranks: _ctx.ranks,
                    licenses: _ctx.licenses,
                    showPreview: _ctx.showPreview,
                    onEdit: _ctx.openEditForm,
                    onUpdateNotes: _ctx.updateNotes
                  }, null, 8, ["member", "logoURL", "companies", "departments", "ranks", "licenses", "showPreview", "onEdit", "onUpdateNotes"]))
                }), 128))
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_v_divider, { class: "border-opacity-50 mt-12 mb-12" })
          ], 64))
        }), 128)),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.deleteRankDialog,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.deleteRankDialog) = $event)),
          "max-width": "350px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Rang löschen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Möchten Sie den Rang " + _toDisplayString(_ctx.rankToDelete.value ? _ctx.rankToDelete.value.name : '') + " wirklich löschen? ", 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "error",
                      text: "",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.deleteRankDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _ctx.deleteRank
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Löschen")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.showSortingModal,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showSortingModal) = $event)),
          "max-width": "600px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Kategorien sortieren ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_draggable, {
                      modelValue: _ctx.ranks,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.ranks) = $event)),
                      list: _ctx.ranks,
                      tag: "transition-group",
                      animation: 150
                    }, {
                      item: _withCtx(({ element }) => [
                        (_openBlock(), _createBlock(_component_v_list_item, {
                          key: element.id,
                          class: "list-item mb-2 d-flex align-center",
                          style: {"cursor":"move"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_avatar, {
                              size: "24",
                              class: "me-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-drag-variant")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createElementVNode("span", null, _toDisplayString(element.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "list"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      color: "error",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showSortingModal = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, { onClick: _ctx.saveCategorySorting }, {
                      default: _withCtx(() => [
                        _createTextVNode("Speichern")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.addRankDialog,
          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.addRankDialog) = $event)),
          width: "500"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Neue Kategorie hinzufügen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_form, { ref: "addRankForm" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  label: "Name",
                                  required: "",
                                  modelValue: _ctx.newRank.name,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newRank.name) = $event)),
                                  onKeydown: _withKeys(_ctx.openAddRankDialog, ["enter"])
                                }, null, 8, ["modelValue", "onKeydown"]),
                                _createVNode(_component_v_select, {
                                  modelValue: _ctx.newRank.department,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newRank.department) = $event)),
                                  label: "Select",
                                  items: _ctx.JobTypes,
                                  "item-title": "name",
                                  "item-value": "id"
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 512)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_actions, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.addRankDialog = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      text: "",
                      onClick: _ctx.addNewRank
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinzufügen")
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}