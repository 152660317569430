
  import { defineComponent, onMounted, ref } from 'vue';
  import { EmployeeCompany, EmployeeDepartment } from '@/types/Members';
  import api from '@/api';
  
  export default defineComponent({
    components: {  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const companies = ref<EmployeeCompany[]>([]);
        const departments = ref<EmployeeDepartment[]>([]);
  
        const fetchCompanies = async () => {
            try {
                const response = await api.post("admin/employee/?action=getCompanies");
                companies.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
        const fetchDepartments = async () => {
            try {
                const response = await api.post("admin/employee/?action=getDepartments");
                departments.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };
  
        const editDialog = ref(false);
        const selectedItem = ref(null);
        const itemType = ref('');
    
        const companyHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const departmentHeaders = [
            { title: 'ID', key: 'id', align: 'start' },
            { title: 'Name', key: 'name' },
            { title: 'Sort Order', key: 'sort_order' },
            { title: 'Actions', key: 'actions' },
        ];
    
        const openEditCompanyDialog = (company) => {
            selectedItem.value = company;
            itemType.value = 'company';
            editDialog.value = true;
        };

        const openEditDepartmentDialog = (department) => {
            selectedItem.value = department;
            itemType.value = 'department';
            editDialog.value = true;
        };

        const saveItem = async () => {
            try {
                if (itemType.value === 'company') {
                    const response = await api.post("admin/employee/?action=saveCompany", selectedItem.value);
                } else if (itemType.value === 'department') {
                    const response = await api.post("admin/employee/?action=saveDepartment", selectedItem.value);
                }
                editDialog.value = false;
                await fetchCompanies();
                await fetchDepartments();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteCompany = async (company) => {
            try {
                const response = await api.post("admin/employee/?action=deleteCompany", { id: company.id });
                await fetchCompanies();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

        const deleteDepartment = async (department) => {
            try {
                const response = await api.post("admin/employee/?action=deleteDepartment", { id: department.id });
                await fetchDepartments();
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };



    const openNewCompanyDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'company';
        editDialog.value = true;
    };

    const openNewDepartmentDialog = () => {
        selectedItem.value = { name: '', sort_order: 0 };
        itemType.value = 'department';
        editDialog.value = true;
    };

    onMounted(async () => {
        await fetchCompanies();
        await fetchDepartments();
    });


    const confirmDialog = ref(false);
    const itemToDelete = ref(null);

    const confirmDelete = (item, type) => {
        itemToDelete.value = { item, type };
        confirmDialog.value = true;
    };

    const proceedWithDelete = async () => {
        try {
            if (itemToDelete.value.type === 'company') {
                await deleteCompany(itemToDelete.value.item);
            } else if (itemToDelete.value.type === 'department') {
                await deleteDepartment(itemToDelete.value.item);
            }
            confirmDialog.value = false;
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    };

    return {
        companies,
        departments,
        companyHeaders,
        departmentHeaders,
        editDialog,
        selectedItem,
        itemType,
        openEditCompanyDialog,
        openEditDepartmentDialog,
        deleteCompany,
        deleteDepartment,
        saveItem,
        openNewCompanyDialog,
        openNewDepartmentDialog,
        confirmDialog,
        itemToDelete,
        confirmDelete,
        proceedWithDelete,
        errorSnackbar,
    };
  },
});
