import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fa877f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.selectedDocument)
        ? (_openBlock(), _createBlock(_component_v_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar, {
                dense: "",
                flat: "",
                ref: "toolbarRef"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _ctx.closeEditor
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => [
                          _createTextVNode("mdi-close")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 512),
              _createVNode(_component_v_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    items: _ctx.categories,
                    "item-title": "name",
                    "item-value": "id",
                    modelValue: _ctx.selectedCategory,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                    label: "Kategorie auswählen"
                  }, null, 8, ["items", "modelValue"]),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "10"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.title,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
                            label: "Titel",
                            "single-line": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        sm: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.sort_order,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sort_order) = $event)),
                            label: "Sort_Order",
                            type: "number",
                            "single-line": ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ckeditor, {
                    editor: _ctx.EditorClass,
                    config: _ctx.EditorClass.defaultConfig,
                    modelValue: _ctx.content,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.content) = $event))
                  }, null, 8, ["editor", "config", "modelValue"]),
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.notes,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.notes) = $event)),
                    label: "Notizen",
                    class: "mt-5"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "success",
                    onClick: _ctx.save,
                    style: {"float":"right"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_ctx.selectedDocumentPreview)
          ? (_openBlock(), _createBlock(_component_v_card, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, {
                  dense: "",
                  flat: "",
                  ref: "toolbarRef"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Vorschau")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      onClick: _ctx.closeEditor
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }, 512),
                _createVNode(_component_v_container, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ckeditor, {
                      editor: _ctx.EditorClass,
                      config: _ctx.EditorClass.defaultConfig,
                      modelValue: _ctx.content,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.content) = $event))
                    }, null, 8, ["editor", "config", "modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_ctx.selectedDocumentEmployeeDocument)
            ? (_openBlock(), _createBlock(_component_v_card, { key: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_toolbar, {
                    dense: "",
                    flat: "",
                    ref: "toolbarRef"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.employeeToEditName), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "success",
                        onClick: _ctx.save
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Speichern")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_btn, {
                        icon: "",
                        onClick: _ctx.closeEditor
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-close")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 512),
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ckeditor, {
                        editor: _ctx.EditorClass,
                        config: _ctx.EditorClass.defaultConfig,
                        modelValue: _ctx.content,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.content) = $event))
                      }, null, 8, ["editor", "config", "modelValue"]),
                      _createVNode(_component_v_textarea, {
                        modelValue: _ctx.notes,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.notes) = $event)),
                        label: "Notizen",
                        class: "mt-5"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        color: "success",
                        onClick: _ctx.save,
                        style: {"float":"right"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Speichern")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
    ])
  ], 512)), [
    [_vShow, _ctx.editorDialog]
  ])
}