
import api from "@/api";
import { defineComponent, ref, onMounted, computed } from "vue";
import { EventOverview } from "@/types/Calendar";
import { TodoOverview } from "@/types/Todo";
import { ApplicantPending } from "@/types/Application"
import "vue-cal/dist/vuecal.css";
import { useStore } from 'vuex';

export default defineComponent({
  components: {
  },
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const store = useStore();
        const Events7Days = ref<EventOverview[]>([]);
        const EventsToday = ref<EventOverview[]>([]);
        const Todos7Days = ref<TodoOverview[]>([]);
        const TodosToday = ref<TodoOverview[]>([]);
        const Applicants = ref<ApplicantPending[]>([]);


        onMounted(async () => {
            await fetchEvents();
            await fetchTodos();
            await fetchPendingApplicant();
        });


        // methods
        async function fetchEvents() {
            try {
                const next7days = await api.get('calendar/?action=getEvents7Days');
                const today = await api.get('calendar/?action=getEventsToday');
                Events7Days.value = next7days.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
                EventsToday.value = today.data;

            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchTodos() {
            try {
                const next7days = await api.get('todo/?action=getTodos7Days');
                const today = await api.get('todo/?action=getTodosToday');
                Todos7Days.value = next7days.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
                TodosToday.value = today.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;	
            }
        }

        async function fetchPendingApplicant() {
            try {
                const response = await api.get('application/?action=getPendingApplicant');
                Applicants.value = response.data; // Speichern Sie die ursprüngliche Ereignisliste in originalEvents
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        }



        function formatDate(dateInput: string | Date, isStart: boolean, onlyDate = false) {
            const date = new Date(dateInput);
            if (!isStart) {
                date.setHours(date.getHours() + 1);
            }

            const timezoneOffset = date.getTimezoneOffset() * 60000;
            const localISOTime = new Date(date.getTime() - timezoneOffset).toISOString().slice(0, -1);

            const [datePart, timePart] = localISOTime.split('T');
            let [hour, minute] = timePart.split(':');

            // Runde die Minuten auf die nächsten 15-Minuten-Schritte
            const roundedMinute = Math.ceil(parseInt(minute) / 15) * 15;
            if (roundedMinute === 60) {
                // Wenn die gerundeten Minuten 60 sind, erhöhe die Stunden um 1 und setze die Minuten auf 0
                hour = (parseInt(hour) + 1).toString().padStart(2, "0");
                minute = "00";
            } else {
                minute = roundedMinute.toString().padStart(2, "0");
            }

            return onlyDate ? datePart : `${datePart}T${hour}:${minute}`;
        }

        function formatDateToDDMMYYYY(datePart: any) {
            let [year, month, day] = datePart.split('-');
            const formattedDate = `${day}.${month}.${year}`;
            return formattedDate;
        }

        function isUserAssigned(users: any){
            if(Object.keys(users).length == 0) return "Fire Department";
            if(users.some(user => user.user_id === parseInt(store.state.user.id))) return "You"
        }

        function formatDateCalPreview(dateInput: string | Date) {
            const date = new Date(dateInput);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        }

        function getDotColor(importance) {
            switch (importance) {
                case 'low':
                return 'green';
                case 'medium':
                return 'orange';
                case 'high':
                return 'red';
                default:
                return 'blue'; // Fallback-Farbe, falls keine Übereinstimmung gefunden wird
            }
        }

        return {
            Events7Days,
            EventsToday,
            formatDateCalPreview,
            store,
            isUserAssigned,
            formatDateToDDMMYYYY,
            formatDate,
            TodosToday, 
            Todos7Days,
            getDotColor,
            Applicants,
            errorSnackbar,
        };
    },
});
