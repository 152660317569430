
  import { defineComponent, onMounted, ref } from 'vue';
  import api from '@/api';
  import axios from 'axios';
  import { Categories } from '@/types/Map';
  
  export default defineComponent({
    setup() {
        const errorSnackbar = ref<any>({ visible: false, message: "" });
        const categories = ref<Categories[]>([]);

        const fetchCategories = async () => {
            try {
                const response = await api.get('admin/map/?action=getCategories');
                categories.value = response.data;
            } catch (error) {
                errorSnackbar.value.message = error.response.data.error;
                errorSnackbar.value.visible  = true;
            }
        };

    const editDialog = ref(false);
    const selectedItem = ref(null);
    const formTitle = ref('');

    const categoryHeaders = [
        { title: 'ID', key: 'id', align: 'start' },
        { title: 'Name', key: 'name' },
        { title: 'Aktuelle Elemente', key: 'marker_count' },
        { title: 'Icon', key: 'icon' },
        { title: 'Actions', key: 'actions' },
    ];

    const openEditCategoryDialog = (category) => {
        selectedItem.value = category;
        formTitle.value = 'Kategorie bearbeiten';
        editDialog.value = true;
    };

    const saveItem = async () => {
        try {
            let response;
            response = await api.post("admin/map/?action=saveCategory", selectedItem.value);
            console.log('Saving category:', selectedItem.value);
            editDialog.value = false;
            await fetchCategories();
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };

    const proceedWithDelete = async () => {
        try {
            const response = await api.post("admin/map/?action=deleteCategory", { id: itemToDelete.value });
            console.log('Deleting category:', itemToDelete.value);
            confirmDialog.value = false;
            await fetchCategories();
        } catch (error) {
            errorSnackbar.value.message = error.response.data.error;
            errorSnackbar.value.visible  = true;
        }
    };


    const openNewCategoryDialog = () => {
      selectedItem.value = { name: '', icon: '' };
      formTitle.value = 'Neue Kategorie hinzufügen';
      editDialog.value = true;
    };

    const confirmDialog = ref(false);
    const itemToDelete = ref(null);

    const confirmDelete = (item) => {
      itemToDelete.value = item;
      confirmDialog.value = true;
    };

    const availableIcons = ref([]);

    const fetchAvailableIconsProd = async () => {
        try {
            const response = await axios.get('/mapIcons.php');
            availableIcons.value = response.data;
        } catch (error) {
            console.error('Error fetching available icons:', error);
        }
    };

    const fetchAvailableIconsDev = async () => {
        try {
            const response = await axios.get('/img/mapIcons/');
            availableIcons.value = response.data
            .filter((filename) => /\.png$/i.test(filename))
            .map((filename) => {
                const fileInfo = filename.split('.');
                const name = fileInfo[0];
                return { name, filename };
            });
        } catch (error) {
            console.error('Error fetching available icons:', error);
        }
    };

    const fetchAvailableIcons = () => {
        if (process.env.NODE_ENV == 'development') {
            fetchAvailableIconsDev();
        } else {
            fetchAvailableIconsProd();
        }
    };


    onMounted(async () => {
      await fetchCategories();
      await fetchAvailableIcons();
    });

    return {
      categories,
      categoryHeaders,
      editDialog,
      selectedItem,
      formTitle,
      openEditCategoryDialog,
      saveItem,
      openNewCategoryDialog,
      confirmDialog,
      itemToDelete,
      confirmDelete,
      proceedWithDelete,
      availableIcons,
      errorSnackbar,
    };
  },
});
